import * as mod from "./../../url";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { CCarousel } from "@coreui/react";
import { CCarouselCaption } from "@coreui/react";
import { CCarouselItem } from "@coreui/react";
import toast from "react-hot-toast";
import { CImage } from "@coreui/react";
const Slider = () => {
  const [uploadedData, setUploadedData] = useState(null);

  const getSlider = async () => {
    try {
      const { data } = await axios.get(
        `${mod.api_url}/api/v1/slider/get-slider`
      );
      if (data.success) {
        setUploadedData(data.sliderData);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong in getting the slider data");
    }
  };
  useEffect(() => {
    getSlider();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <CCarousel controls indicators interval={2000}>
        {uploadedData &&
          uploadedData?.map((item, index) => (
            <CCarouselItem className="line-by-line">
              <CImage
                className="d-block w-100 "
                // src={require(`./../../img/dynamicSlider/${item.image}`)}
                src={`${mod.api_url}/api/v1/slider/get-slider/${item._id}`}
                alt="Uploaded"
              />
              <CCarouselCaption
                className={` ${index % 2 === 0 ? "odd-card" : "even-card"}`}
                key={item._id}
              ></CCarouselCaption>
            </CCarouselItem>
          ))}
      </CCarousel>
    </div>
  );
};
export default Slider;
