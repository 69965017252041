import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Center,
  Heading,
  Stack,
  Text,
  Image,
  Divider,
} from "@chakra-ui/react";
import { FaBookOpenReader } from "react-icons/fa6";
import { useCart } from "../context/cart";

const PaymentSuccess = () => {
  const [cart, setCart] = useCart();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem("cart");
    setCart([]);
  }, [setCart]);

  const handleGoToLibrary = () => {
    navigate("/dashboard/user/orders");
  };

  const handleBrowseMore = () => {
    navigate("/");
  };

  return (
    <Center h="100vh" bg="gray.100" p={4}>
      <Box
        maxW="lg"
        w="full"
        bg="white"
        boxShadow="lg"
        borderRadius="lg"
        p={6}
        textAlign="center"
      >
        <Heading as="h1" size="lg" mb={4} color="green.600">
          Payment Successful!
        </Heading>
        <Text fontSize="lg" mb={6}>
          Thank you for your purchase. Your books are now available in your
          library.
        </Text>

        <Divider mb={4} />

        <Stack direction={["column", "row"]} spacing={4} justify="center">
          <Button colorScheme="blue" onClick={handleGoToLibrary}>
            Go to My Library
          </Button>
          <Button variant="outline" onClick={handleBrowseMore}>
            Browse More Books
          </Button>
        </Stack>
      </Box>
    </Center>
  );
};

export default PaymentSuccess;
