import { Box, Heading } from "@chakra-ui/react";
import React, { useEffect } from "react";
import TopHeader from "../components/Layout/TopHeader";
import FooterArea from "../components/Layout/FooterArea";

const Retur_and_Exchange_Policy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <TopHeader />
      <div className="col-md-12 ">
        <img
          src="/images/Return-and-Exchange-Policy.jpg"
          alt="contactus"
          style={{ width: "100%" }}
        />
      </div>
      <Box padding="30px">
        <Heading> Return and Exchange Policy</Heading>
        <p>
          At Kirush Essential Multiservices Pvt. Ltd., we prioritize providing
          our customers with top-quality books and a seamless shopping
          experience. However, we understand that there might be occasions where
          a return or exchange is necessary. To ensure a clear and smooth
          process, we have established the following return and exchange policy:
        </p>
        <h5>1. Eligibility for Returns and Exchanges</h5>
        <p>
          To be eligible for a return or exchange, please make sure your item
          meets the following criteria:
        </p>
        <ul>
          <li>
            <strong>Time Frame :</strong>You must initiate the return or
            exchange within 5 to 7 days from the date of delivery.
          </li>
          <li>
            <strong>Product Condition :</strong>The product must be in its
            original, unused condition and free from any damage. The item should
            be returned in its original packaging, along with any accompanying
            tags, labels, or additional materials (e.g., bookmarks, promotional
            inserts) that were included.
          </li>
          <li>
            <strong>Proof of Purchase</strong>A valid receipt, invoice, or proof
            of purchase is required to process the return or exchange.
          </li>
        </ul>

        <h5>2. Non-Returnable and Non-Exchangeable Items</h5>
        <p>
          Certain products are not eligible for return or exchange. These
          include but are not limited to:
        </p>
        <ul>
          <li>
            <strong>E-books or Digital Products:</strong>Any digital content,
            such as e-books or audiobooks, is non-returnable and non-refundable
            once downloaded.
          </li>
          <li>
            <strong>Custom or Personalized Products:</strong>If the product has
            been customized or personalized (e.g., custom cover designs,
            personal messages), it cannot be returned or exchanged.
          </li>
          <li>
            <strong>Discounted or Sale Items:</strong> Items purchased during
            special promotions or on clearance are typically not eligible for
            return or exchange.
          </li>
        </ul>
        <h5>3. Process for Returns</h5>
        <p>To initiate a return, please follow these steps:</p>
        <ol>
          <li>
            <strong>Contact Customer Service :</strong>Email us at
            info@kitabsaller.com or call us at +91 7669001308 within 5 to 7 days
            of receiving the product. Please include:
          </li>
          <ul>
            <li>Your order number.</li>
            <li>The reason for the return.</li>
            <li>Photos of the product (if damaged or defective).</li>
          </ul>
          <li>
            <strong>Review and Instructions: </strong>Our team will review your
            request and provide return instructions. If applicable, we will also
            issue a return shipping label.
          </li>
          <li>
            <strong>Pack the Item: </strong>Securely repack the item in its
            original packaging, including all parts, and affix the provided
            return shipping label to the package.
          </li>
          <li>
            <strong>Processing Your Return:</strong> Once we receive the
            returned item, our quality control team will inspect it. If the item
            meets the return eligibility criteria, we will process your refund
            within 1 to 2 business days.
          </li>
        </ol>
        <h5>4. Refund Policy</h5>
        <p>
          Refunds are issued based on the original payment method. Details are
          as follows:
        </p>
        <ul>
          <li>
            <strong>Original Payment Method: </strong>The refund will be
            credited back to the same payment method used to make the purchase
            (e.g., credit card, bank transfer).
          </li>
          <li>
            <strong>Refund Timeline: </strong>Refunds will be processed within
            1to 2 business days after the returned item passes inspection.
          </li>
          <li>
            <strong>Shipping Costs:</strong>Shipping charges are non-refundable
            unless the return is due to an error on our part, such as a
            defective or incorrect product.
          </li>
          <li>
            <strong>Partial Refunds:</strong> In some cases, partial refunds may
            be granted, such as when:
            <ul>
              <li>The product shows signs of use.</li>
              <li>
                The item is not returned in its original packaging or condition.
              </li>
              <li>Original accessories or parts are missing.</li>
            </ul>
          </li>
        </ul>
        <h5>5. Exchange Policy</h5>
        <p>
          If you wish to exchange a product for a different item, the process is
          as follows:
        </p>
        <ol>
          <li>
            <strong>Contact Customer Service: </strong> Reach out to us at
            info@kitabsaller.com within 5to 7 days of receiving the product.
            Provide your order number and details of the product you'd like to
            exchange.
          </li>
          <li>
            <strong>Availability Check:</strong>Our team will confirm the
            availability of the new product and provide instructions for
            returning the original item.
          </li>
          <li>
            <strong>Return the Original Product: </strong>
            Use the return process described above to ship the original item
            back to us. Once we receive and inspect the product, we will ship
            the new product to you.
          </li>
          <h6>
            <strong>Note :</strong>
            <ul>
              <li>
                <strong>Price Difference: </strong>If the replacement product is
                of a higher price than the original, the difference will need to
                be paid before the exchange is completed.
              </li>
              <li>
                <strong>Shipping Costs: </strong>Customers are responsible for
                the return shipping costs, unless the exchange is due to a
                defect or incorrect product sent by us.
              </li>
            </ul>
          </h6>
        </ol>
        <h5>6. Damaged, Defective, or Incorrect Products</h5>
        <p>
          If you receive a damaged, defective, or incorrect product, please
          notify us immediately. You must contact us within 48 hours of
          receiving the product by emailing info@kitabsaller.com and providing:
        </p>
        <ul>
          <li>
            <strong>Photos: </strong>Attach clear photos of the damaged or
            defective product.
          </li>
          <li>
            <strong>Assessment and Return:</strong>Our team will assess the
            issue and provide instructions for returning the product or
            receiving a replacement.
          </li>
          <li>
            <strong>Full Refund or Replacement:</strong> If the product is
            confirmed to be damaged, defective, or incorrect, we will offer a
            full refund or send a replacement at no additional cost.
          </li>
        </ul>
        <h5>7. Cancellations</h5>
        <ul>
          <li>
            <strong>Cancellation Window:</strong>You can cancel your order
            within 24 hours of placing it without any penalties.
          </li>
          <li>
            <strong>Shipped Orders:</strong>Once the product has been shipped,
            cancellation is no longer possible. If you wish to return the item,
            you will need to follow the standard return process.
          </li>
        </ul>
        <h5>8. Customer Service</h5>
        <p>
          If you have any questions about our return or exchange process, our
          dedicated customer service team is here to help. Feel free to contact
          us via:
        </p>
        <ol>
          <li>
            <strong>Email : </strong>info@kitabsaller.com
          </li>
          <li>
            <strong>Phone:</strong> +91 7669001308
          </li>
          <li>
            <strong>Operating Hours:</strong>24 hours
          </li>
        </ol>
        <p>
          At Kirush Essential Multiservices Pvt. Ltd., we value your
          satisfaction and strive to address any concerns or issues you may have
          with your purchase. Thank you for choosing us, and we look forward to
          serving you.
        </p>
        <p>
          This content provides a user-friendly outline of your return and
          exchange policy, ensuring transparency and trust with your customers.
          Let me know if you'd like to add or modify anything further!
        </p>
      </Box>
      <FooterArea />
    </>
  );
};

export default Retur_and_Exchange_Policy;
