import React, { useState } from "react";
import axios from "axios";
import { useNavigate, Link, NavLink } from "react-router-dom";
import "../../styles/AuthStyles.css";
import * as mod from "./../../url";
import { Grid, GridItem, useToast } from "@chakra-ui/react";
import {
  Input,
  Button,
  useDisclosure,
  Center,
  FormLabel,
  Image,
  Flex,
  Container,
  Text,
  VStack,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "@chakra-ui/react";
import "./Login.css";
import { ToastContainer } from "react-toastify";
import { FaEyeLowVision } from "react-icons/fa6";
import { FaEye } from "react-icons/fa";
import logo from "./../../img/logo.png";
import bannerregister from "./../../img/kitaab_sellar03_register.jpg";

const Register = () => {
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    phone: "",
    otp: "",
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const toast = useToast();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const generateOTP = async () => {
    if (formData.phone.length !== 10) {
      return toast.error("Invalid phone number");
    }

    try {
      const res = await axios.post(`${mod.api_url}/api/v1/auth/generate-otp`, {
        phone: formData.phone,
        email: formData.email,
        mode: "new user",
      });
      toast({
        title: "OTP sent on mobile & email.",
        status: "success",
        duration: 2000,
        position: "top-right",
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error while sending OTP",
        status: "error",
        duration: 2000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const isValidForm = () => {
    const { name, email, password, phone, otp } = formData;
    const nameRegex = /^[A-Za-z\s]+$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    const phoneRegex = /^([+]\d{2}[ ])?\d{10}$/;
    const otpRegex = /^([+]\d{2}[ ])?\d{6}$/;

    const newErrors = {};

    if (!name.match(nameRegex)) {
      newErrors.name = "Please enter a valid name.";
    }

    if (!email.match(emailRegex)) {
      newErrors.email = "Please enter a valid email address.";
    }

    if (!password.match(passwordRegex)) {
      newErrors.password = "Wrong format of password.";
    }

    if (!phone.match(phoneRegex)) {
      newErrors.phone = "Please enter a valid phone number.";
    }
    if (!otp.match(otpRegex)) {
      newErrors.otp = "Please enter a valid otp number.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isValidForm()) {
      try {
        const res = await axios.post(
          `${mod.api_url}/api/v1/auth/register`,
          formData
        );
        if (res && res.data.success) {
          toast({
            title: res.data.message,
            position: "top-right",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
          setTimeout(() => {
            navigate("/login");
          }, 2000);
          onClose();
        } else {
          toast.error(res.data.message);
        }
      } catch (error) {
        toast({
          title: "Something went wrong",
          position: "top-right",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: "Fill the form correctly",
        position: "top-right",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const doubleClicked = () => {
    if (
      !formData.name ||
      !formData.email ||
      !formData.password ||
      !formData.phone
    ) {
      toast({
        title: "Fill all the Input",
        position: "top-right",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    generateOTP();
    onOpen();
  };

  return (
    <>
      <div className="row" style={{ width: "100%" }}>
        <Grid
          templateColumns={{ base: "1fr", md: "1fr 1fr" }}
          gap={4}
          width="100%"
          height="100vh"
        >
          <GridItem>
            <Image src={bannerregister} className="image_main" />
          </GridItem>

          <GridItem
            alignItems="center"
            justifyContent="center"
            backgroundSize="cover"
            backgroundPosition="center"
            border="1px solid #d4d4d4"
            padding="10px"
            margin="10px"
          >
            <Container width="70%">
              <Center>
                <Image src={logo} width="200px" />
              </Center>
              <Text
                fontSize="x-large"
                marginTop={3}
                textAlign="center"
                fontWeight="700"
              >
                SignUp
              </Text>
              <VStack spacing="5px" color="black">
                <div className="row" style={{ padding: "10px" }}>
                  <div className="col-md-6">
                    {" "}
                    <FormLabel>Enter Name</FormLabel>
                    <Input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Enter Your Name"
                      variant="outlined"
                      error={!!errors.name}
                      helperText={errors.name}
                    />
                  </div>
                  <div className="col-md-6">
                    <FormLabel>Email address</FormLabel>
                    <Input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      label="Email"
                      variant="outlined"
                      placeholder="Enter Your email"
                      error={!!errors.email}
                      helperText={errors.email}
                    />
                  </div>
                </div>

                <div className="row" style={{ padding: "10px" }}>
                  <div className="col-md-6">
                    <FormLabel>Enter Password</FormLabel>
                    <InputGroup>
                      <Input
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        label="Password"
                        variant="outlined"
                        placeholder="Password"
                        error={!!errors.password}
                        helperText={errors.password}
                        type={show ? "text" : "password"}
                      />
                      <InputRightElement
                        width="20%"
                        height="80%"
                        marginRight="6px"
                        marginTop="5px"
                      >
                        <Button h="96%" onClick={handleClick}>
                          {show ? <FaEyeLowVision /> : <FaEye />}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </div>
                  <div className="col-md-6">
                    {" "}
                    <FormLabel>Enter Phone No</FormLabel>
                    <Input
                      type="text"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      label="Phone"
                      variant="outlined"
                      placeholder="Enter Your mobile"
                      error={!!errors.phone}
                      helperText={errors.phone}
                    />
                  </div>
                </div>
              </VStack>

              <Center>
                <Button
                  marginTop={3}
                  width="50%"
                  colorScheme="blue"
                  onClick={doubleClicked}
                >
                  Gerate OTP
                </Button>
              </Center>

              <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader textAlign="center">Verify OTP</ModalHeader>
                  <ModalBody>
                    <Input
                      type="text"
                      name="otp"
                      value={formData.otp}
                      onChange={handleChange}
                      label="OTP"
                      variant="outlined"
                      placeholder="OTP"
                      error={!!errors.otp}
                      helperText={errors.otp}
                    />
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      colorScheme="green"
                      width="48%"
                      m={1}
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                    <Button width="48%" colorScheme="red" onClick={onClose}>
                      Close Modal
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
              <hr />
              <div className="signup-link">
                <NavLink to="/forgot-password">Forgot password?</NavLink>
              </div>
              <div className="signup-link">
                <NavLink to="/login">If You Registered Member? SignIn</NavLink>
              </div>
            </Container>
          </GridItem>
          <ToastContainer />
        </Grid>
      </div>
    </>
  );
};

export default Register;
