import React, { useState, useEffect } from "react";
import Layout from "./../components/Layout/Layout";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import "../styles/ProductDetailsStyles.css";
import { useCart } from "../context/cart";
import * as mod from "./../../src/url.js";
import {
  FaBookReader,
  FaBusinessTime,
  FaFilePdf,
  FaStar,
} from "react-icons/fa";
import { FcLike } from "react-icons/fc";
import { FcDislike } from "react-icons/fc";
import { TbCertificate, TbCertificateOff } from "react-icons/tb";
import Modal from "react-modal";
import { Hourglass } from "react-loader-spinner";
import { useAuth } from "../context/auth.js";
import { toast } from "react-toastify";
import { Button, Grid, GridItem, Heading, Image, Text } from "@chakra-ui/react";
import { BsHourglass } from "react-icons/bs";
import { GiLaptop } from "react-icons/gi";
import { PiArticleMediumLight } from "react-icons/pi";

const colorOptions = ["#4285F4", "#EA4335", "#FBBC05", "#34A853"];

const ProductDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState({});
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [cart, setCart] = useCart([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [rating, setRating] = useState(0);
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [auth, setAuth] = useAuth();
  const [likeStatus, setLikeStatus] = useState([]);
  useEffect(() => {
    // Initialize likeStatus with false for each review
    setLikeStatus(Array(product?.reviews?.length).fill(false));
  }, [product.reviews]);
  const handleLikeReview = async (reviewIndex) => {
    try {
      const userId = auth.user._id;
      // console.log(userId, "userId");
      const updatedLikeStatus = [...likeStatus];
      const review = product.reviews[reviewIndex];

      if (!review.likes.includes(userId)) {
        review.likes.push(userId);

        await axios.put(
          `/api/v1/product/${product._id}/reviews/${reviewIndex}/like`,
          {
            userId: userId,
          }
        );

        updatedLikeStatus[reviewIndex] = true;
        setLikeStatus(updatedLikeStatus);
      } else {
        toast.error("User has already liked this review");
      }
    } catch (error) {
      console.error("Error liking review:", error);
    }
  };

  useEffect(() => {
    if (params?.slug) getProduct();
  }, [params?.slug]);

  //getProduct
  const getProduct = async () => {
    try {
      const { data } = await axios.get(
        `${mod.api_url}/api/v1/product/get-product/${params.slug}`
      );
      setProduct(data?.product);
      setRating(data?.product?.rating || 0);
      getSimilarProduct(data?.product._id, data?.product.category._id);
    } catch (error) {
      console.log(error);
    }
  };

  const getSimilarProduct = async (pid, cid) => {
    // window.location.reload();
    try {
      const { data } = await axios.get(
        `${mod.api_url}/api/v1/product/related-product/${pid}/${cid}`
      );
      setRelatedProducts(data?.products);
      setSelectedImage(null);
    } catch (error) {
      console.log(error);
    }
  };

  // console.log(colorProducts,'ppppppppppp')

  const buyNow = () => {
    const existingProduct = cart.find((item) => item._id === product._id);
    if (existingProduct) {
      navigate("/cart");
    } else {
      // If the product doesn't exist, add it to the cart and redirect to the checkout page
      setCart([
        ...cart,
        { ...product, quantity: 1, size: selectedSize, colors: selectedColor },
      ]);
      localStorage.setItem(
        "cart",
        JSON.stringify([
          ...cart,
          {
            ...product,
            quantity: 1,
            size: selectedSize,
            colors: selectedColor,
          },
        ])
      );

      navigate("/cart");
    }
  };

  const handleImageClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const reviewsToShowInitially = 5;
  const [visibleReviews, setVisibleReviews] = useState(reviewsToShowInitially);

  const handleLoadMore = () => {
    setVisibleReviews(
      (prevVisibleReviews) => prevVisibleReviews + reviewsToShowInitially
    );
  };
  const result = (() => {
    let ratingCounts = {};

    product?.reviews?.forEach((review) => {
      const { rating } = review;
      ratingCounts[rating] = (ratingCounts[rating] || 0) + 1;
    });

    return { ratingCounts };
  })();

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <hr
        style={{
          background:
            "linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet)",
          height: "5px",
          border: "none",
        }}
      />{" "}
      <div className="row product-details justify-content-center single-page  ">
        <Grid
          templateColumns={{ base: "1fr", md: "1fr 1fr" }}
          gap={4}
          width="100%"
        >
          <GridItem>
            <div className="w-[45%] flex-1 flex flex-col p-6">
              <Image
                src={product.image}
                alt="banner Image"
                width="100%"
                height="auto"
              />
            </div>

            <Button
              width="100%"
              marginBottom="3px"
              colorScheme="green"
              fontSize={14}
              className="but"
              onClick={buyNow}
              marginTop={2}
            >
              Buy Now{" "}
            </Button>
          </GridItem>

          <GridItem>
            <div
              className="scroller-bar"
              style={{
                height: "500px",
                width: "100%",
                overflowY: "auto",
                scrollbarWidth: "thin",
                WebkitOverflowScrolling: "touch",
                marginLeft: "8px",
                padding: "10px",
              }}
            >
              <style>
                {`
                  ::-webkit-scrollbar {
                    width: 5px;
                    height: 0px;
                  }
                  ::-webkit-scrollbar-thumb {
                    background-color: #ccc; // Set your desired scrollbar color
                  }
                `}
              </style>
              <Text color="black" fontSize="x-large" fontFamily="poppins">
                {product.name}
              </Text>
              <h6>
                {product.offers > 0 ? (
                  <Text
                    size="sm"
                    fontFamily="poppins"
                    color="green"
                    marginBottom="3px"
                  >
                    {(() => {
                      const prices = (product.price * product.offers) / 100;
                      const finalPrice = product.price - prices;
                      const disc = product.price - finalPrice;
                      return (
                        <>
                          You save Extra Rs.
                          {disc.toFixed(2)} off
                        </>
                      );
                    })()}
                    &nbsp; &nbsp;
                    <del>₹{product.price}</del>
                    &nbsp; &nbsp; {product.offers}% off !!!!
                  </Text>
                ) : (
                  <h6 style={{ color: "red" }}>
                    {(() => {
                      const prices = (product.price * product.offers) / 100;
                      const finalPrice = product.price - prices;
                      const disc = product.price - finalPrice;
                      return (
                        <>
                          <span>Rs. {finalPrice.toFixed(2)}</span>
                        </>
                      );
                    })()}
                  </h6>
                )}
              </h6>
              <Text
                color="black"
                size="sm"
                fontFamily="poppins"
                marginBottom="3px"
              >
                <strong> Category : </strong>
                {product?.course?.name}
              </Text>

              <div className="row">
                <div className="col-12">
                  <Text color="black">Description: </Text>
                  <h6>
                    {/* {product.description} */}
                    {(() => {
                      const pro = product.description;
                      if (typeof pro !== "string") {
                        return <div>Invalid description</div>;
                      }
                      const wordsArray = pro.split(";");
                      return (
                        <>
                          <div>
                            {wordsArray.map((info, index) => (
                              <p key={index}>
                                {" "}
                                <span style={{ fontSize: 20 }}>.</span> &nbsp;
                                {info.trim()}
                              </p>
                            ))}
                          </div>
                        </>
                      );
                    })()}
                  </h6>
                  <div>{product && <div></div>}</div>
                </div>

                <h1>{product.title}</h1>
                <div className="rating-and-review">
                  <h style={{ marginLeft: "5px", marginRight: "9px" }}>
                    {product?.reviews?.length}, Ratings & Reviews:
                  </h>
                  <div
                    className="review-total-pper"
                    style={{ display: "inline-flex", background: "green" }}
                  >
                    <h6 style={{ display: "inline-flex" }}>
                      <h6
                        style={{
                          color: "white",
                          marginTop: "3px",
                          marginLeft: "2px",
                        }}
                      >
                        5
                      </h6>
                    </h6>
                    <FaStar
                      style={{
                        color: "white",
                        display: "inline-flex",
                        marginTop: "2px",
                        marginLeft: "3px",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div>
                <hr
                  style={{
                    background:
                      "linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet)",
                    height: "5px",
                    border: "none",
                  }}
                />{" "}
              </div>
            </div>
          </GridItem>
        </Grid>
        <hr
          style={{
            background:
              "linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet)",
            height: "5px",
            border: "none",
          }}
        />
      </div>
    </Layout>
  );
};
export default ProductDetails;
