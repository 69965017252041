import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Policy from "./pages/Policy";
import Pagenotfound from "./pages/Pagenotfound";
import Register from "./pages/Auth/Register";
import Login from "./pages/Auth/Login";
import Dashboard from "./pages/user/Dashboard";
import PrivateRoute from "./components/Routes/Private";
import ForgotPasssword from "./pages/Auth/ForgotPasssword";
import Orders from "./pages/user/Orders";
import Profile from "./pages/user/Profile";
import Search from "./pages/Search";
import ProductDetails from "./pages/ProductDetails";
import CartPage from "./pages/CartPage";
import PaymentPage from "./pages/PaymentPage";
import OrdersDetails from "./pages/user/OrdersDetails";
import CategoryProduct from "./pages/CategoryProduct";
import OurMission from "./pages/OurMission";
import CancellationsPolicy from "./pages/CancellationsPolicy";
import Categories from "./pages/Categories";
import TermsansConditions from "./pages/TermsansConditions";
import PaymentSuccess from "./pages/PaymentSuccess";
import PaymentFailure from "./pages/PaymentFailure";
import Retur_and_Exchange_Policy from "./pages/Retur_and_Exchange_Policy";
import Shipping_Policy from "./pages/Shipping_Policy";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/product/:slug" element={<ProductDetails />} />
        <Route path="/course/:slug" element={<CategoryProduct />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/search" element={<Search />} />
        <Route path="/courses" element={<Categories />} />
        <Route path="/cart/payments" element={<PaymentPage />} />
        <Route path="/payment-failure" element={<PaymentFailure />} />
        <Route path="/payment-success" element={<PaymentSuccess />} />
        <Route path="/dashboard" element={<PrivateRoute />}>
          <Route path="user" element={<Dashboard />} />
          <Route path="user/orders" element={<Orders />} />
          <Route path="user/orders/:_id" element={<OrdersDetails />} />
          <Route path="user/profile" element={<Profile />} />
        </Route>
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPasssword />} />
        <Route path="/login" element={<Login />} />
        <Route path="/privacy-policy" element={<Policy />} />
        <Route path="/about-us" element={<OurMission />} />
        <Route path="/Cancellations-policy" element={<CancellationsPolicy />} />
        <Route path="/terms-and-condition" element={<TermsansConditions />} />
        <Route path="/contact" element={<Contact />} />
        <Route
          path="/Retur-and-Exchange-Policy"
          element={<Retur_and_Exchange_Policy />}
        />
        <Route path="/Shipping-Policy" element={<Shipping_Policy />} />
        <Route path="*" element={<Pagenotfound />} />
        {/* <Route path="*" element={<Pagenotfound />} /> */}
      </Routes>
    </>
  );
}

export default App;
