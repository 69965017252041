import React from "react";
import "./../../styles/FooterArea.css";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram, FaTwitter, FaWhatsapp } from "react-icons/fa";
import { Text } from "@chakra-ui/react";

function FooterArea() {
  return (
    <>
      <div>
        <div className="footer-area">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <h4 className="footer-heading">Let's Read Hear</h4>
                <hr />
                <div className="footer-underline"></div>
                <p>
                  kitabsaller Elevate your learning experience with our
                  comprehensive, offering cutting-edge Books, expert guidance,
                  and seamless user experience for professional growth.
                </p>
              </div>
              <div className="col-md-4">
                <h4 className="footer-heading">Quick Links</h4> <hr />
                <div className="footer-underline"></div>
                <div className="mb-2">
                  <Link to="/" className="text-black">
                    Home
                  </Link>
                </div>
                <div className="mb-2">
                  <Link to="/about-us" className="text-black">
                    About US
                  </Link>
                </div>
                <div className="mb-2">
                  <Link to="/contact" className="text-black">
                    Contact Us
                  </Link>
                </div>
                <div className="mb-2">
                  <Link to="/privacy-policy" className="text-black">
                    Privacy Policy
                  </Link>
                </div>
                <div className="mb-2">
                  <Link to="/terms-and-condition" className="text-black">
                    Terms and Condition
                  </Link>
                </div>
                <div className="mb-2">
                  <Link to="/Retur-and-Exchange-Policy" className="text-black">
                    Return and Exchange Policy
                  </Link>
                </div>
                <div className="mb-2">
                  <Link to="/Shipping-Policy" className="text-black">
                    Shipping Policy
                  </Link>
                </div>
                <div className="mb-2">
                  <Link to="/Cancellations-policy" className="text-black">
                    Cancellations Policy
                  </Link>
                </div>
              </div>

              <div className="col-md-4">
                <h4 className="footer-heading">Reach Us</h4> <hr />
                <div className="footer-underline"></div>
                <div className="mb-2">
                  <p>
                    <i className="fa fa-map-marker"></i> Building No./Flat No.:
                    2561/194, Third Floor Road/Street: Tri Nagar
                    City/Town/Village: 2561/194, Third Floor District: North
                    West Delhi State: Delhi PIN Code:110035
                  </p>
                </div>
                <div className="mb-2">
                  <a href="/" className="text-black">
                    <i className="fa fa-phone"></i> +91 7669001308
                  </a>
                </div>
                <div className="mb-2">
                  <a href="/" className="text-black">
                    <i className="fa fa-envelope"></i> info@kitabsaller.com
                  </a>
                </div>
                <span className="text-bold">
                  <span className="social-Icons">
                    <a href="https://www.facebook.com/profile.php?id=61565036854447 ">
                      <FaFacebook size={16} className="icon" />
                    </a>

                    <a
                      href="https://www.instagram.com/kitab.saller/"
                      target="_blank"
                    >
                      <FaInstagram size={16} className="icon" />
                    </a>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area">
          <div className="container">
            <div className="row">
              <div className="col-md-11">
                <Text textAlign="center">
                  {" "}
                  All Rights Reserved &copy; 2024 - kitabsaller - E-commerce
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FooterArea;
