import React, { useState, useEffect } from "react";
import "./../../styles/ScreenProducts.css";
import axios from "axios";
import { CImage } from "@coreui/react";
import * as mod from "./../../../src/url";
import toast from "react-hot-toast";
import { Grid, GridItem } from "@chakra-ui/react";

function ScreenProducts() {
  const [isImageVisible, setIsImageVisible] = useState(false);
  const [uploadedData, setUploadedData] = useState(null);

  const getAllImg = async () => {
    try {
      const response = await axios.get(
        `${mod.api_url}/api/vi/multiple/multiple-get`
      );
      if (response.data.success) {
        setUploadedData(response.data.data);
      }
    } catch (error) {
      console.log(error);
      toast.error(
        "Something went wrong in getting the MultipleImageMultipleImage data"
      );
    }
  };
  useEffect(() => {
    getAllImg();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const scrollThreshold = 200;
      if (scrollPosition < scrollThreshold) {
        setIsImageVisible(true);
      } else {
        setIsImageVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div
        className={`banner_big_image ${isImageVisible ? "visible" : "visible"}`}
      >
        <div className="row">
          <Grid
            templateColumns={{ base: "1fr", md: "1fr 1fr" }}
            gap={4}
            width="100%"
          >
            {uploadedData &&
              uploadedData?.map((item, index) => (
                <GridItem className={`col-md-12 mt-4 `} key={item._id}>
                  <div
                    className="card "
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "contents",
                    }}
                  >
                    <CImage
                      className="card-img-top banner-imgs"
                      src={`${mod.api_url}/api/vi/multiple/sliderImageII/${item._id}`}
                      alt="Uploaded"
                    />
                  </div>
                </GridItem>
              ))}
          </Grid>
        </div>
      </div>
    </>
  );
}

export default ScreenProducts;
