import React, { useEffect, useState } from "react";
import "./ProductSliders.css";
import axios from "axios";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link, useNavigate } from "react-router-dom";
import * as mod from "./../../src/url.js";
import {
  Box,
  Button,
  Center,
  Flex,
  GridItem,
  Heading,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useCart } from "../context/cart";

function ProductSliders() {
  const [categories, setCategories] = useState([]);
  const [isImageVisible, setIsImageVisible] = useState(false);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [cart, setCart] = useCart();
  const [total, setTotal] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);

  // Scroll effect for showing/hiding image visibility
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const scrollThreshold = 10;
      setIsImageVisible(scrollPosition < scrollThreshold);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Fetch categories
  const getAllCategory = async () => {
    try {
      const { data } = await axios.get(
        `${mod.api_url}/api/v1/course/get-course`
      );
      if (data?.success) {
        setCategories(data?.course);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllCategory();
  }, []);

  // Fetch products
  const getAllProducts = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${mod.api_url}/api/v1/product/product-list/${page}`
      );
      setLoading(false);

      if (page === 1) {
        setProducts(data.products);
      } else {
        setProducts((prevProducts) => [...prevProducts, ...data?.products]);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, [page]);

  // Responsive settings for the carousel
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 600 },
      items: 4,
      slidesToSlide: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  const loaderStyle = {
    display: "inline-block",
    width: "60px",
    height: "60px",
    border: "3px solid lightgray",
    borderRadius: "50%",
    borderTop: "8px solid blue",
    animation: "spin 2s linear infinite",
  };
  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "auto",
  };
  const loaderKeyframes = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

  // Render
  return (
    <>
      <hr />
      <div className="app_compo">
        <div className="py-3 app">
          <div className="catogryslid">
            <div className="row">
              <div className="col-12">
                <h2
                  className="mb-3"
                  style={{
                    fontFamily: "monospace",
                    fontSize: 18,
                    color: "black",
                  }}
                >
                  Top Selling Books
                </h2>
              </div>
              <Carousel
                responsive={responsive}
                autoPlay={true}
                autoPlaySpeed={2000}
                removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                infinite={true}
              >
                {loading ? (
                  <>
                    <Center>
                      {" "}
                      <div style={containerStyle}>
                        <div style={loaderStyle}></div>
                      </div>{" "}
                    </Center>
                  </>
                ) : (
                  products?.map((p) => (
                    <GridItem
                      key={p._id}
                      onClick={() => {
                        window.scrollTo({
                          top: 0,
                          left: 0,
                          behavior: "smooth",
                        });
                      }}
                      borderWidth="1px"
                      borderRadius="lg"
                      overflow="hidden"
                      margin="10px"
                    >
                      <Image
                        src={p.image}
                        alt="Product Image"
                        borderRadius="lg"
                        width="100%"
                        height="auto"
                        objectFit="cover"
                        padding={1}
                      />
                      <VStack p={2} align="stretch">
                        <Heading size="sm" fontFamily="poppins" color="black">
                          {p.name.slice(0, 30)}
                        </Heading>
                        <VStack align="left">
                          <Text fontFamily="poppins" color="black">
                            {p.description.slice(0, 40)}...
                          </Text>
                          <Box display="flex">
                            <Text fontSize="small" color="black">
                              Offers: {p.offers}%
                            </Text>
                            <Text
                              color="black"
                              fontSize="small"
                              marginLeft="65px"
                            >
                              Price: ₹ {p.price}
                            </Text>
                          </Box>
                        </VStack>
                        <Flex justifyContent="space-between">
                          <Button
                            width="100%"
                            colorScheme="green"
                            fontSize="small"
                            onClick={() => {
                              const existingProduct = cart.find(
                                (item) => item._id === p._id
                              );
                              if (existingProduct) {
                                navigate("/cart");
                              } else {
                                setCart([...cart, { ...p, quantity: 1 }]);
                                localStorage.setItem(
                                  "cart",
                                  JSON.stringify([
                                    ...cart,
                                    { ...p, quantity: 1 },
                                  ])
                                );
                                navigate("/cart");
                              }
                            }}
                          >
                            Buy Now
                          </Button>
                        </Flex>
                      </VStack>
                    </GridItem>
                  ))
                )}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductSliders;
