import React, { useState } from "react";
import axios from "axios";
import { Link, NavLink, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import "../../styles/AuthStyles.css";
import * as mod from "./../../url";
import FooterArea from "../../components/Layout/FooterArea";
import TopHeader from "../../components/Layout/TopHeader";
import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  FormLabel,
  Grid,
  GridItem,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { ToastContainer } from "react-toastify";
import { FaEye, FaEyeLowVision } from "react-icons/fa6";
import logo from "./../../img/logo.png";
import bannerforget from "./../../img/kitaab_sellar02_forget.jpg";

const ForgotPasssword = () => {
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const toast = useToast();
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    newPassword: "",
    phone: "",
    otp: "",
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // form function
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        `${mod.api_url}/api/v1/auth/forgot-password`,
        formData
      );

      if (res && res.data.success) {
        toast({
          title: "Password Forget Sucessfully.",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        navigate("/login");
        setFormData("");
        onClose();
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast({
        title: "Password Forget Error.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } finally {
      onClose();
    }
  };
  const generateOTP = async () => {
    if (formData.phone.length !== 10) {
      return toast.error("Invalid phone number");
    }

    try {
      const res = await axios.post(`${mod.api_url}/api/v1/auth/generate-otp`, {
        phone: formData.phone,
        email: formData.email,
        mode: "reset password",
      });
      toast.success("OTP sent successfully");
    } catch (error) {
      console.error(error);
      toast.error("Error while sending OTP");
    }
  };

  const doubleClick = () => {
    if (!formData.phone || !formData.email || !formData.newPassword) {
      toast({
        title: "Fill the all Input",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
    generateOTP();
    onOpen();
  };

  return (
    <>
      <div className="row" style={{ width: "100%", height: "auto" }}>
        <Grid
          templateColumns={{ base: "1fr", md: "1fr 1fr" }}
          gap={4}
          width="100%"
          height="100vh"
        >
          {/* First Column (Image) - Hidden on mobile */}
          <GridItem>
            <Image src={bannerforget} className="image_main" />
          </GridItem>

          {/* Second Column (Form) */}
          <GridItem
            alignItems="center"
            justifyContent="center"
            backgroundSize="cover"
            backgroundPosition="center"
            border="1px solid #d4d4d4"
            padding="10px"
            margin="10px"
          >
            <Container width="70%" padding="10px">
              <Center>
                <Image src={logo} width="200px" />
              </Center>
              <Text
                fontSize="x-large"
                marginTop={3}
                textAlign="center"
                fontWeight="700"
              >
                SignUp
              </Text>
              <VStack spacing="5px" color="black">
                <div className="row" style={{ padding: "10px" }}>
                  <div className="col-md-6">
                    <FormLabel>Enter Phone</FormLabel>
                    <Input
                      type="phone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      id="exampleInputEmail1"
                      placeholder="Resigterd Mobile"
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <FormLabel>Enter Email</FormLabel>
                    <Input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      id="exampleInputEmail1"
                      placeholder="Resigterd Email"
                      required
                    />
                  </div>
                </div>

                <div className="row" style={{ padding: "10px" }}>
                  <div className="col-md-8">
                    <FormLabel>Enter Password</FormLabel>
                    <InputGroup>
                      <Input
                        name="newPassword"
                        value={formData.newPassword}
                        onChange={handleChange}
                        id="exampleInputPassword1"
                        placeholder="New Password"
                        required
                        type={show ? "text" : "password"}
                      />
                      <InputRightElement
                        width="20%"
                        height="80%"
                        marginRight="6px"
                        marginTop="5px"
                      >
                        <Button h="96%" onClick={handleClick}>
                          {show ? <FaEyeLowVision /> : <FaEye />}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </div>
                  <div className="col-md-4">
                    {" "}
                    <FormLabel> Gerate OTP</FormLabel>
                    <Button
                      colorScheme="blue"
                      width="100%"
                      onClick={doubleClick}
                    >
                      Gernate
                    </Button>
                  </div>
                </div>
              </VStack>

              <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader textAlign="center">Verify OTP</ModalHeader>
                  <ModalBody>
                    <Input
                      type="text"
                      name="otp"
                      value={formData.otp}
                      onChange={handleChange}
                      label="OTP"
                      variant="outlined"
                      placeholder=" OTP"
                    />
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      width="48%"
                      colorScheme="green"
                      onClick={handleSubmit}
                      m={1}
                    >
                      Submit
                    </Button>
                    <Button width="48%" colorScheme="red" onClick={onClose}>
                      Close Modal
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
              <hr />
              <div className="signup-link">
                <NavLink to="/register">Not a member? Signup now</NavLink>
              </div>
              <div className="signup-link">
                <NavLink to="/login">If You Registered Member? SignIn</NavLink>
              </div>
            </Container>
          </GridItem>

          <ToastContainer />
        </Grid>
      </div>
    </>
  );
};

export default ForgotPasssword;
