import { Box, Heading } from "@chakra-ui/react";
import React, { useEffect } from "react";
import TopHeader from "../components/Layout/TopHeader";
import FooterArea from "../components/Layout/FooterArea";

const Shipping_Policy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <TopHeader />
      <div className="col-md-12 ">
        <img
          src="/images/Shipping.jpg"
          alt="contactus"
          style={{ width: "100%" }}
        />
      </div>
      <Box padding="30px">
        <Heading>Shipping Policy</Heading>
        <p>
          At Kirush Essential Multiservices Pvt. Ltd., we are dedicated to
          delivering your books and educational materials in a timely, secure,
          and reliable manner. We have designed this shipping policy to clarify
          how we handle orders, shipping costs, delivery timelines, and other
          shipping-related concerns.
        </p>
        <h5>1. General Shipping Information</h5>
        <h6>Processing Time:</h6>
        <p>
          All orders placed on our website are processed within 1-2 business
          days. Processing time includes order verification, quality check,
          packaging, and preparing the shipment for dispatch. Orders placed
          after 5:00 PM IST or on weekends and holidays will be processed on the
          next business day.
        </p>
        <h6>Shipping Days:</h6>
        <p>
          We ship orders Monday through Saturday. Orders are not shipped on
          Sundays or on public holidays. Any orders placed during these days
          will be processed on the next working day.
        </p>
        <p>
          Once your order has been shipped, you will receive a confirmation
          email with tracking details so you can monitor your shipment's
          progress.
        </p>
        <h5>2. Shipping Destinations</h5>
        <p>We offer shipping to the following destinations:</p>
        <ol>
          <li>
            <strong>
              Domestic Shipping (India): We deliver to all major cities and
              towns across India, including remote areas. We partner with
              trusted domestic courier services to ensure timely and safe
              deliveries.
            </strong>
          </li>
          <li>
            <strong>International Shipping:</strong>We currently offer
            international shipping to select countries. If your country is not
            listed during checkout, please contact our customer service team at
            info@kitabsaller.com to inquire about shipping availability.
            International orders are subject to longer delivery times and
            additional customs regulations.
          </li>
        </ol>
        <h5>3. Shipping Rates</h5>
        <h6>Domestic Shipping:</h6>
        <p>
          For orders within India, shipping rates are calculated based on the
          weight of the package, the destination, and the shipping option
          selected at checkout. We offer the following shipping options:
        </p>
        <ol>
          <li>
            <strong>Standard Shipping:</strong>
            Delivery time: 5-7 business days. <br />
            Cost: Flat rate of ₹50 for orders under ₹500. Free shipping for
            orders above ₹500.
          </li>
          <li>
            <strong>Express Shipping:</strong>
            Delivery time: 2-3 business days.
            <br />
            Cost: ₹100 for all orders.
          </li>
        </ol>
        <h6>International Shipping:</h6>
        <p>
          International shipping rates vary depending on the destination
          country, the weight of the package, and the shipping method selected.
          The applicable shipping charges will be displayed at checkout before
          you confirm your order. International shipping costs generally start
          at ₹1,000 and may increase based on the total weight of the shipment.
        </p>
        <p>
          Please note that customers are responsible for any customs fees,
          taxes, or import duties that may be applied by their country's customs
          regulations. These fees are not included in our shipping rates and
          must be paid separately upon delivery.
        </p>
        <h5>4. Estimated Delivery Times</h5>
        <p>
          The estimated delivery times for orders depend on your location and
          the shipping method chosen. Below is a general guide for delivery
          times:
        </p>
        <ul>
          <li>
            <strong>mestic Shipping (within India):</strong>
            <ul>
              <li>
                <strong>Standard Shipping:</strong>Delivery typically takes 5-7
                business days after your order has been dispatched.
              </li>
              <li>
                <strong>Express Shipping:</strong>Delivery usually occurs within
                2-3 business days after dispatch.
              </li>
            </ul>
          </li>
          <li>
            <strong>International Shipping:</strong>International delivery times
            can range from 10-12 business days, depending on the destination
            country and customs processing. Please note that these are estimated
            delivery times and may vary due to unforeseen delays in transit or
            customs.
          </li>
        </ul>
        <h5>5. Tracking Your Order</h5>
        <p>
          Once your order has been dispatched, you will receive an email
          containing your tracking number and a link to the courier’s website,
          where you can monitor the status of your delivery in real-time. Please
          allow 24 hours for the tracking information to update on the courier’s
          system.
        </p>
        <p>
          For domestic orders, tracking information is typically available
          immediately after dispatch. For international orders, tracking updates
          may take a little longer to reflect, depending on the courier service
          and the destination country.
        </p>
        <p>
          If you encounter any issues with tracking your order, feel free to
          contact our customer service team at info@kitabsaller.com for
          assistance.
        </p>
        <h5>6. Delayed or Missing Deliveries</h5>
        <p>
          At Kirush Essential Multiservices Pvt. Ltd., we strive to ensure that
          all orders are delivered on time. However, there are certain factors
          beyond our control that may cause delays, including:
        </p>
        <ul>
          <li>Severe weather conditions.</li>
          <li>Public holidays and other unforeseen events.</li>
          <li>Customs processing delays (for international shipments).</li>
          <li>Incorrect shipping information provided at checkout.</li>
        </ul>
        <p>
          If your order is delayed beyond the estimated delivery time, we
          recommend checking your tracking information for updates. If you
          notice no updates for more than 5 business days (domestic) or 10
          business days (international), please contact us immediately at
          info@kitabsaller.com so we can investigate and resolve the issue.
        </p>
        <p>
          For missing packages or orders that are marked as delivered but were
          not received, please contact our support team within 48 hours of the
          indicated delivery date.
        </p>
        <h5>7. Address Errors and Re-shipping Fees</h5>
        <p>
          It is the customer’s responsibility to ensure that the shipping
          address provided at checkout is accurate and complete. If a package is
          returned to us due to an incorrect or incomplete address, the customer
          will be responsible for re-shipping fees to resend the package.
        </p>
        <p>
          To avoid this issue, please double-check your shipping address before
          confirming your order. If you realize there is a mistake after placing
          your order, contact us immediately at info@kitabsaller.com so we can
          update the shipping details before dispatch.
        </p>
        <h5>8. Handling of Damaged or Lost Packages</h5>
        <p>
          In the unlikely event that your order arrives damaged or is lost in
          transit, please reach out to our customer service team within 48 hours
          of receiving the damaged product or after the estimated delivery date
          for lost packages.
        </p>
        <h6>For damaged products:</h6>
        <ul>
          <li>
            Send clear photos of the damaged packaging and product to
            [info@kitabsaller.com].
          </li>
          <li>
            Our team will assess the damage and arrange for a replacement or
            refund.
          </li>
        </ul>
        <h6>For lost packages:</h6>
        <ul>
          <li>
            We will contact the shipping carrier to investigate the issue and
            provide you with an update as soon as possible.
          </li>
          <li>
            If the package is deemed lost, we will either reship the order at no
            extra cost or issue a full refund.
          </li>
        </ul>
        <h5>9. International Customs and Duties</h5>
        <p>
          International orders may be subject to additional customs duties,
          taxes, or other import fees, depending on the destination country.
          These charges are the responsibility of the customer and are not
          included in the purchase price or shipping charges.
        </p>
        <p>
          Customs policies vary widely from country to country, so we recommend
          checking with your local customs office for information regarding
          customs duties and fees. Delays due to customs processing are beyond
          our control, and we appreciate your understanding and patience.
        </p>
        <h5>10. Customer Service</h5>
        <p>
          If you have any questions about our shipping policy or require
          assistance with your order, please feel free to contact our customer
          service team. We are here to help!
        </p>
        <ul>
          <li>
            <strong>Email:</strong> info@kitabsaller.com{" "}
          </li>
          <li>
            <strong>Phone:</strong> +91 7669001308{" "}
          </li>
          <li>
            <strong>Operating Hours:</strong> 24 Hours{" "}
          </li>
        </ul>
        <p>
          At Kirush Essential Multiservices Pvt. Ltd., we value your business
          and strive to make your shopping experience as smooth and satisfactory
          as possible. Our team is dedicated to ensuring your order arrives on
          time and in excellent condition.
        </p>
        <p>
          This content thoroughly covers the shipping policy for your business,
          providing clarity and transparency for your customers. Let me know if
          you need any adjustments!
        </p>
      </Box>
      <FooterArea />
    </>
  );
};

export default Shipping_Policy;
