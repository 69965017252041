import React, { useState } from "react";
import { useCart } from "../context/cart";
import { useAuth } from "../context/auth";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import {
  Button,
  Center,
  Divider,
  Heading,
  Stack,
  Text,
  Image,
  Box,
  HStack,
  Grid,
  GridItem,
  Input,
  FormLabel,
  FormControl,
  Textarea,
  ModalFooter,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  useDisclosure,
} from "@chakra-ui/react";
import * as mod from "./../../src/url.js";
import { IoArrowRedoSharp } from "react-icons/io5";
import "./Paymentpage.css";

function PaymentPage() {
  const [auth] = useAuth();
  const [cart] = useCart();
  const [loading, setLoading] = useState(false);
  const [showCoins, setShowCoins] = useState(false);
  const navigate = useNavigate();
  const deliveryCharge = 50;

  // Calculate total amount of the cart items
  const calculateTotalAmount = () => {
    return cart.reduce((total, item) => {
      const discountAmount = (item.price * item.offers) / 100;
      const finalPrice = item.price - discountAmount;
      return total + finalPrice * item.quantity;
    }, 0);
  };

  const handlePayment = async () => {
    try {
      setLoading(true);
      setShowCoins(true);
      const amount = calculateTotalAmount();
      const orderData = {
        paymentMethod: "online",
        products: cart.map((item) => item._id),
        orderItems: cart.map((item) => ({
          name: item.name,
          image: item.image || "",
          product: item._id,
          pdf: item.pdf,
          price: item.price,
          quantity: item.quantity,
        })),
        itemsPrice: amount,
        shippingPrice: amount > 499 ? 0 : deliveryCharge,
        taxPrice: 0,
        totalPrice: amount > 499 ? amount : amount + deliveryCharge,
        buyer: { _id: auth.user?._id },
      };

      const { data } = await axios.post(
        `${mod.api_url}/api/v1/product/phonepe/initiate`,
        orderData
      );
      window.location.href = data.url;
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Payment failed");
    }
  };
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    postalCode: "",
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Submit form data logic here
    console.log("Customer Address:", formData);
    onOpen(); // Open the modal after form submission
  };
  const closehandle = (e) => {
    onClose();
  };

  return (
    <>
      <div className="row" style={{ width: "100%" }}>
        {/* Left section: Display all the books */}
        <Grid
          templateColumns={{ base: "1fr", md: "1fr 1fr" }}
          gap={4}
          width="100%"
        >
          <GridItem spacing={4}>
            <Heading as="h2" size="lg" textAlign="center" mb={6}>
              Order Summary
            </Heading>
            <Divider mb={4} />
            {cart.map((item, index) => {
              const discountAmount = (item.price * item.offers) / 100;
              const finalPrice = item.price - discountAmount;
              return (
                <Box key={index} p={4} borderWidth={1} borderRadius="md">
                  <Image
                    src={item.image}
                    alt={item.name}
                    borderRadius="md"
                    boxSize="200px"
                    height="auto"
                    objectFit="cover"
                  />
                  <Box>
                    <Text fontWeight="bold">{item.name}</Text>
                    <Text>Price: ₹{item.price.toFixed(2)}</Text>
                    <Text>Discount: ₹{discountAmount.toFixed(2)}</Text>
                    <Text>Quantity: {item.quantity}</Text>
                    <Text fontWeight="bold">
                      Final Price: ₹{(finalPrice * item.quantity).toFixed(2)}
                    </Text>
                  </Box>
                </Box>
              );
            })}
          </GridItem>
          <GridItem p={4}>
            <Heading as="h2" size="lg" textAlign="center" mb={6}>
              Order Payment Details
            </Heading>
            <Divider mb={4} />
            <Text fontSize="lg" fontWeight="bold" mb={4}>
              Total Amount: ₹{calculateTotalAmount().toFixed(2)}
            </Text>

            <Box
              maxW="600px"
              mx="auto"
              p="6"
              boxShadow="lg"
              borderRadius="md"
              bg="white"
            >
              <form onSubmit={handleSubmit}>
                <FormControl id="name" mb="4">
                  <FormLabel>Name</FormLabel>
                  <Input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Enter your full name"
                    required
                  />
                </FormControl>

                <FormControl id="email" mb="4">
                  <FormLabel>Email</FormLabel>
                  <Input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Enter your email"
                    required
                  />
                </FormControl>

                <FormControl id="phone" mb="4">
                  <FormLabel>Phone Number</FormLabel>
                  <Input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="Enter your phone number"
                    required
                  />
                </FormControl>

                <FormControl id="address" mb="4">
                  <FormLabel>Address</FormLabel>
                  <Textarea
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    placeholder="Enter your street address"
                    required
                  />
                </FormControl>

                <FormControl id="city" mb="4">
                  <FormLabel>City</FormLabel>
                  <Input
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    placeholder="Enter your city"
                    required
                  />
                </FormControl>

                <FormControl id="postalCode" mb="4">
                  <FormLabel>Postal Code</FormLabel>
                  <Input
                    type="text"
                    name="postalCode"
                    value={formData.postalCode}
                    onChange={handleChange}
                    placeholder="Enter your postal code"
                    required
                  />
                </FormControl>

                <Button type="submit" colorScheme="blue" width="full" mt="4">
                  Submit
                </Button>
              </form>

              {/* Payment Modal */}
              <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                  <ModalBody>
                    <Text textAlign="center" fontWeight="bold" mb="4">
                      Please verify your details before proceeding to payment:
                    </Text>

                    <Box mb="2">
                      <Text>
                        <strong>Name:</strong> {formData.name}
                      </Text>
                    </Box>

                    <Box mb="2">
                      <Text>
                        <strong>Email:</strong> {formData.email}
                      </Text>
                    </Box>

                    <Box mb="2">
                      <Text>
                        <strong>Phone:</strong> {formData.phone}
                      </Text>
                    </Box>

                    <Box mb="2">
                      <Text>
                        <strong>Address:</strong> {formData.address}
                      </Text>
                    </Box>

                    <Box mb="2">
                      <Text>
                        <strong>City:</strong> {formData.city}
                      </Text>
                    </Box>

                    <Box mb="2">
                      <Text>
                        <strong>Postal Code:</strong> {formData.postalCode}
                      </Text>
                    </Box>

                    <Box mb="4">
                      <Text>
                        <strong>Total Amount:</strong> ₹
                        {calculateTotalAmount().toFixed(2)}
                      </Text>
                    </Box>

                    {true /* Replace this with auth.user condition */ ? (
                      <Button
                        width="100%"
                        colorScheme="blue"
                        fontSize="lg"
                        onClick={handlePayment}
                        isLoading={loading}
                        loadingText="Processing Payment"
                      >
                        Pay Now <IoArrowRedoSharp /> ₹
                        {calculateTotalAmount().toFixed(2)}
                      </Button>
                    ) : (
                      <Button
                        width="100%"
                        colorScheme="red"
                        fontSize="lg"
                        onClick={() => navigate("/login")}
                      >
                        Login to Pay
                      </Button>
                    )}

                    {/* Coins animation */}
                    {showCoins && (
                      <div className="coin-container">
                        {[...Array(10)].map((_, index) => (
                          <div key={index} className="coin" />
                        ))}
                      </div>
                    )}
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      width="100%"
                      colorScheme="red"
                      onClick={closehandle}
                    >
                      Cancel Payment
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </Box>
          </GridItem>{" "}
        </Grid>
      </div>
    </>
  );
}

export default PaymentPage;
