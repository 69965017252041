import React, { useState, useEffect } from "react";
import Layout from "./../components/Layout/Layout";
import { useCart } from "../context/cart";
import { useAuth } from "../context/auth";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import "../styles/CartStyles.css";
import * as mod from "./../../src/url.js";
import { BsCurrencyRupee } from "react-icons/bs";
import {
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { Card, CardBody } from "@chakra-ui/react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";

const CartPage = () => {
  const [auth] = useAuth();
  const [cart, setCart] = useCart();
  const [amount, setAmount] = useState("");
  const [discount, setDiscount] = useState("");
  const navigate = useNavigate();
  const lessPrice = discount - amount;

  const calculateTotalAmount = () => {
    return cart.reduce((total, item) => {
      const prices = (item.price * item.offers) / 100;
      const finalPrice = item.price - prices;
      return total + finalPrice * item.quantity;
    }, 0);
  };

  const calculateOldAmount = () => {
    return cart.reduce((total, item) => {
      return total + item.price * item.quantity;
    }, 0);
  };

  const removeCartItem = (pid) => {
    let myCart = [...cart];
    let index = myCart.findIndex((item) => item._id === pid);
    myCart.splice(index, 1);
    setCart(myCart);
    localStorage.setItem("cart", JSON.stringify(myCart));
  };

  useEffect(() => {
    setAmount(calculateTotalAmount());
    setDiscount(calculateOldAmount());
    window.scrollTo(0, 0);
  }, [cart]);

  const handlePlaceOrder = () => {
    navigate("/cart/payments");
  };

  const handleAddToCart = () => {
    navigate("/");
  };

  return (
    <Layout>
      <div className="row" style={{ width: "100%" }}>
        <h3 className="text-center" style={{ color: "black" }}>
          {!auth?.user
            ? "Hello Guest"
            : `Hello  ${auth?.token && auth?.user?.name}`}
          <p className="text-center" style={{ color: "black" }}>
            {cart?.length
              ? `You Have ${cart.length} items in your cart ${
                  auth?.token ? "" : "please login to checkout !"
                }`
              : " Your Cart Is Empty"}
          </p>
        </h3>

        {cart?.length ? (
          <Grid
            templateColumns={{ base: "1fr", md: "1fr 1fr" }}
            gap={4}
            width="100%"
          >
            <GridItem>
              {cart?.map((item) => (
                <Card
                  key={item._id}
                  maxW="100%"
                  marginBottom="10px"
                  onClick={() => {
                    navigate(`/product/${item.slug}/`);
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  <CardBody>
                    <Flex
                      direction={{ base: "column", md: "row" }}
                      align="center"
                    >
                      <Image
                        src={item.image}
                        alt={item.name}
                        objectFit="cover"
                        width="100px"
                        height="100px"
                      />
                      <Stack
                        spacing="1"
                        flex="1"
                        align="stretch"
                        pl={{ base: "0", md: "10px" }}
                      >
                        <Heading size="md">{item.name}</Heading>
                        <Text color="blue.600" fontSize="2xl">
                          ₹ {item.price}
                        </Text>
                      </Stack>
                      <Button
                        colorScheme="red"
                        width="80px"
                        padding="10px"
                        mt={{ base: "10px", md: "0" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          removeCartItem(item._id);
                        }}
                      >
                        Remove
                      </Button>
                    </Flex>
                  </CardBody>
                </Card>
              ))}
            </GridItem>

            <GridItem>
              <TableContainer>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Quantity</Th>
                      <Th>Discount</Th>
                      <Th>Final Amount</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>{cart.length} Item(s)</Td>
                      <Td>
                        {lessPrice.toLocaleString("en-US", {
                          style: "currency",
                          currency: "INR",
                        })}
                      </Td>
                      <Td>
                        <BsCurrencyRupee /> {amount}
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>

              <Button
                width="100%"
                colorScheme="green"
                fontSize={10}
                mt="10px"
                mb="35px"
                onClick={handlePlaceOrder}
              >
                Place Order
              </Button>
            </GridItem>
          </Grid>
        ) : (
          <div className="text-center">
            <h4>Your Cart is Empty</h4>
            <Button
              width="30%"
              colorScheme="green"
              marginBottom={3}
              fontSize={10}
              onClick={handleAddToCart}
            >
              Shop Now
            </Button>
            <iframe
              src="https://giphy.com/embed/8PA8Ew3nw97yg"
              width="100%"
              height="auto"
              frameBorder="0"
              className="giphy-embed"
              allowFullScreen
              title="Empty Cart"
            ></iframe>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default CartPage;
