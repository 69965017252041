import React, { useEffect } from "react";
import "./../styles/Contact.css";
import Layout from "./../components/Layout/Layout";
import { BiMailSend, BiPhoneCall, BiSupport } from "react-icons/bi";
import FooterArea from "../components/Layout/FooterArea";
import TopHeader from "../components/Layout/TopHeader";
import { Box } from "@mui/material";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <TopHeader />
      <div className="col-md-12 ">
        <img
          src="/images/contact-us.jpg"
          alt="contactus"
          style={{ width: "100%" }}
        />
      </div>
      <Box padding="30px">
        <p>
          At Kirush Essential Multiservices Pvt. Ltd., we are committed to
          providing the best possible service to our customers. Whether you have
          a question about our products, need help with an order, or want to
          share feedback, we’re here to assist you. We believe in open
          communication and strive to respond to every inquiry with care and
          attention.
        </p>
        <h1>How to Reach Us</h1>
        <p>
          We have multiple channels through which you can get in touch with us.
          Our customer support team is ready to answer your queries and provide
          assistance with any issues you may face.
        </p>
        <h3>Phone Support:</h3>
        <p>
          We are available over the phone during business hours for any
          immediate concerns or questions.
          <br />
          Phone Number: +91 7669001308 <br />
          Business Hours: Monday to Saturday, 9:00 AM – 6:00 PM (IST) <br />
        </p>
        <h3>Email Support:</h3>
        <p>
          For any inquiries, complaints, or feedback, feel free to drop us an
          email. Our team will respond to you within 24-48 hours.
          <br />
          Email Address: sallerkitab@gmail.com
        </p>
        <h3>Office Address:</h3>
        <p>
          If you would like to visit us or send us mail, our office address is:{" "}
          <br />
          Kirush Essential Multiservices Pvt. Ltd. Company name : Building
          No./Flat No.: 2561/194, Third Floor
          <br />
          Road/Street: Tri Nagar
          <br />
          City/Town/Village: 2561/194, Third Floor
          <br />
          District: North West Delhi
          <br />
          State: Delhi
          <br />
          PIN Code:110035
          <br />
          India
        </p>
        <h3>Online Inquiry Form</h3>
        <p>
          To make it easier for you to reach us, we have provided an online
          inquiry form on our website. Just fill in your details, and one of our
          team members will get back to you as soon as possible. Whether you
          need more information about a particular product or have a question
          regarding your order, our team is here to help.
        </p>
        <h3>FAQs</h3>
        <p>
          We have also created a Frequently Asked Questions (FAQs) section on
          our website to provide quick answers to common queries. Whether you're
          wondering about our shipping policies, payment options, or product
          returns, you can check our FAQs for detailed information.
        </p>
        <h3>Connect with Us on Social Media</h3>
        <p>
          Stay up to date with the latest news, updates, and promotions by
          following us on social media. We regularly post new arrivals, reading
          tips, and more on our platforms. Plus, it's a great way to interact
          with us directly!
        </p>
      </Box>
      <FooterArea />
    </>
  );
};

export default Contact;
