import React, { useEffect } from "react";
import Layout from "./../components/Layout/Layout";
import "./../styles/Policy.css";
import TopHeader from "../components/Layout/TopHeader";
import FooterArea from "../components/Layout/FooterArea";
import { Box } from "@chakra-ui/react";

const Policy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <TopHeader />
      <div className="col-md-12 ">
        <img
          src="/images/PRIVACY-POLICY.jpg"
          alt="contactus"
          style={{ width: "100%" }}
        />
      </div>
      <Box padding="30px">
        <p>
          At Kirush Essential Multiservices Pvt. Ltd., your privacy is important
          to us. This Privacy Policy outlines how we collect, use, and protect
          the personal information you provide to us when you interact with our
          website, services, and products. By using our website and services,
          you agree to the terms of this Privacy Policy.
        </p>
        <h1>Information We Collect</h1>
        <p>
          We collect information from you when you interact with us through our
          website, make purchases, or engage with our services. The types of
          information we collect include:
        </p>
        <h6>Personal Information:</h6>
        <ul typeof="circle">
          <li>Name</li>
          <li>Email address</li>
          <li>Contact information</li>
          <li>Shipping and billing address</li>
          <li>
            Payment information (credit card/debit card details, bank account
            information)
          </li>
          <li>Username and password (for accounts created on our website)</li>
        </ul>
        <br />
        <h6>Non-Personal Information:</h6>

        <ul typeof="circle">
          <li>IP address</li>
          <li>Browser type and version</li>
          <li>Device information</li>
          <li>Cookies and tracking data (for website usage analytics)</li>
          <li>
            We may also collect other information you provide to us through
            emails, surveys, or other forms of communication.
          </li>
        </ul>

        <h3>3. How We Use Your Information</h3>
        <p>
          The information we collect is used to provide and improve our
          services. Specifically, we use your information for the following
          purposes:
        </p>
        <p>
          Order Processing: To process and fulfill your orders, including
          shipping, billing, and customer support. Account Management: To create
          and manage your account on our platform, including authentication and
          security purposes. Customer Communication: To communicate with you
          regarding your orders, account status, or other inquiries. This
          includes sending order confirmations, updates, and support messages.
        </p>

        <p>
          Marketing and Promotions: With your consent, we may use your
          information to send you promotional materials, newsletters, or special
          offers related to our products and services. You may opt-out of these
          communications at any time.
        </p>

        <p>
          Personalization: To customize your experience on our website,
          including personalized product recommendations, content, and offers.
          Improvement of Services: To analyze and improve our website’s
          performance, user experience, and product offerings. We use
          non-personal data like website traffic and user behavior for this
          purpose.
        </p>
        <p>
          Legal Compliance: To comply with legal obligations and enforce our
          terms and conditions, including fraud prevention and payment
          verification.
        </p>
        <h3>4. How We Share Your Information</h3>
        <p>
          We respect your privacy and do not sell or rent your personal
          information to third parties. However, we may share your information
          in the following circumstances:
        </p>

        <p>
          Service Providers: We may share your information with trusted
          third-party service providers who assist us in operating our website,
          processing payments, fulfilling orders, or providing customer support.
          These service providers are required to maintain the confidentiality
          and security of your information. Business Transfers: In the event of
          a merger, acquisition, or sale of assets, your personal information
          may be transferred as part of the transaction. We will notify you if
          your data is transferred and becomes subject to a different privacy
          policy.
        </p>
        <p>
          Legal Obligations: We may disclose your information if required by
          law, legal process, or governmental request. This includes sharing
          data to protect the rights, property, or safety of Kirush Essential
          Multiservices Pvt. Ltd., our customers, or others.
        </p>

        <h3>4. Cookies and Tracking Technologies</h3>
        <p>
          We use cookies and similar tracking technologies to enhance your
          experience on our website. Cookies are small files stored on your
          device that allow us to remember certain information about your visit,
          such as your preferences or login status.
        </p>
        <ul>
          <h4>We use the following types of cookies:</h4>
          <li>
            Essential Cookies: These cookies are necessary for the basic
            functioning of our website, such as enabling secure logins and
            shopping cart functionality.
          </li>
          <li>
            Analytics Cookies: These cookies help us analyze how users interact
            with our website, which allows us to improve the performance and
            user experience.
          </li>
          <li>
            Marketing Cookies: These cookies are used to deliver personalized
            advertisements based on your browsing behavior and interests.
          </li>
          <li>
            You can control cookie preferences through your browser settings.
            However, disabling certain cookies may affect the functionality of
            our website.
          </li>
        </ul>

        <h3>5. Data Security</h3>
        <p>
          We take the security of your personal information seriously and
          implement appropriate technical and organizational measures to protect
          it from unauthorized access, loss, or misuse. These measures include:
        </p>

        <p>
          Encryption of sensitive data (e.g., payment information) during
          transmission.
          <br />
          Regular monitoring of our systems for vulnerabilities and security
          threats.
          <br />
          Restricted access to personal information, ensuring only authorized
          personnel can access it.
          <br />
          While we strive to protect your information, no security system is
          impenetrable. We encourage you to use strong passwords and be cautious
          when sharing personal information online.
          <br />
        </p>
        <h3>6. Your Rights and Choices</h3>
        <p>
          You have the following rights regarding your personal information:
        </p>
        <ul>
          <li>
            Access: You can request a copy of the personal information we hold
            about you.
          </li>
          <li>
            Correction: If any of your information is incorrect or outdated, you
            have the right to request corrections.
          </li>
          <li>
            Deletion: You may request the deletion of your personal information,
            subject to legal obligations or legitimate business interests that
            require us to retain certain data.
          </li>
          <li>
            Opt-Out: You may opt-out of marketing communications at any time by
            clicking the "unsubscribe" link in the email or by contacting us
            directly. To exercise any of these rights, please contact us using
            the information provided in the “Contact Us” section below.
          </li>
        </ul>
        <h3>7. Children's Privacy</h3>
        <p>
          Our services are not directed toward individuals under the age of 18,
          and we do not knowingly collect personal information from children. If
          we become aware that we have inadvertently collected information from
          a child, we will take steps to delete it as soon as possible. If you
          believe we may have collected information from a child, please contact
          us immediately.
        </p>
        <h3>8. Changes to This Privacy Policy</h3>
        <p>
          We may update this Privacy Policy from time to time to reflect changes
          in our practices or legal obligations. Any updates will be posted on
          this page with the effective date of the new policy. We encourage you
          to review this Privacy Policy periodically to stay informed about how
          we protect your information.
        </p>
        <h3>9. Contact Us</h3>
        <p>
          If you have any questions or concerns about this Privacy Policy or how
          we handle your personal information, please contact us:
          <br />
          Kirush Essential Multiservices Pvt. Ltd.
          <br />
          Email: support@kirushessential.com
          <br />
          Phone: 7669001308
        </p>
      </Box>
      <FooterArea />
    </>
  );
};

export default Policy;
