import React, { useState, useEffect } from "react";
import Layout from "../components/Layout/Layout";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Checkbox, Divider, Radio } from "antd";
import { Prices, Colorpic, Discount } from "../components/Prices";
import * as mod from "./../../src/url.js";
import { useCurrency } from "../context/CurrencyChange.js";
import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const CategoryProduct = () => {
  const params = useParams();
  const navigate = useNavigate();
  // const { selectedCurrency } = useCurrency();
  const [products, setProducts] = useState([]);
  const [course, setCourse] = useState({});
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (params?.slug) {
      getProductsByCoursesetCourse();
    }
  }, [params?.slug]);
  useEffect(() => {
    getAllCategories();
    getTotal();
    getAllProducts();
  }, []);

  useEffect(() => {
    if (page === 1) {
      return;
    }
    loadMore();
  }, [page]);

  const getProductsByCoursesetCourse = async () => {
    try {
      const { data } = await axios.get(
        `${mod.api_url}/api/v1/product/product-course/${params.slug}`
      );
      setProducts(data?.products);
      setCourse(data?.course);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllProducts = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${mod.api_url}/api/v1/product/product-list/${page}`
      );
      setLoading(false);
      setProducts(data.products);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getAllCategories = async () => {
    try {
      const { data } = await axios.get(
        `${mod.api_url}/api/v1/course/get-course`
      );
      if (data?.success) {
        setCategories(data?.course);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTotal = async () => {
    try {
      const { data } = await axios.get(
        `${mod.api_url}/api/v1/product/product-count`
      );
      setTotal(data?.total);
    } catch (error) {
      console.log(error);
    }
  };

  const loadMore = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${mod.api_url}/api/v1/product/product-list/${page}`
      );
      setLoading(false);
      setProducts([...products, ...data?.products]);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <div className="mt-3 course" style={{ background: "#f4f1ee" }}>
        <h4
          className="text-center"
          style={{ color: "black", marginTop: "10px" }}
        >
          CoursesetCourse - {course?.name}
        </h4>
        <Divider />
        <h6 className="text-center">{products?.length} results found</h6>
        <div className="row">
          <Box marginTop={2}>
            <Container maxW="container.xl" p={4}>
              <Grid
                templateColumns={{
                  base: "repeat(1, 1fr)",
                  md: "repeat(3, 1fr)",
                }}
                gap={6}
              >
                {products?.map((p) => (
                  <GridItem
                    key={p._id}
                    onClick={() => navigate(`/product/${p.slug}/`)}
                    borderWidth="1px"
                    borderRadius="lg"
                    overflow="hidden"
                  >
                    {p.image.length > 0 && (
                      <Image
                        src={p.image}
                        alt="uploading...."
                        borderRadius="lg"
                        width="100%"
                        height="auto"
                        objectFit="cover"
                        padding={1}
                      />
                    )}
                    <VStack p={2} align="stretch">
                      <Heading color="black" size="md" fontFamily="poppins">
                        {p.name.slice(0, 20)}
                      </Heading>
                      <VStack align="left" display="inline">
                        <Text color="black" fontFamily="poppins">
                          {p.description.slice(0, 80)}...
                        </Text>
                        <Box display="flex">
                          <Text color="black" fontSize="large">
                            Offers: {p.offers}%
                          </Text>
                          <Text
                            color="black"
                            fontSize="large"
                            marginLeft="50px"
                          >
                            Price: ₹ {p.price}
                          </Text>
                        </Box>
                      </VStack>
                      <Flex justifyContent="space-between">
                        {" "}
                        <Button
                          width="100%"
                          colorScheme="green"
                          fontSize={10}
                          onClick={() => navigate(`/product/${p.slug}`)}
                        >
                          View Course
                        </Button>
                      </Flex>
                    </VStack>
                  </GridItem>
                ))}
              </Grid>
            </Container>
          </Box>
          <Box>
            <div className="m-2 p-3">
              {products && products.length < total && (
                <Button
                  width="30%"
                  colorScheme="green"
                  fontSize={10}
                  className=""
                  onClick={(e) => {
                    e.preventDefault();
                    setPage(page + 1);
                  }}
                >
                  {loading ? "Loading ..." : "Loadmore"}
                </Button>
              )}
            </div>
          </Box>
        </div>
      </div>
    </Layout>
  );
};

export default CategoryProduct;
