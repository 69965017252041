import { Box, Heading } from "@chakra-ui/react";
import React, { useEffect } from "react";
import TopHeader from "../components/Layout/TopHeader";
import FooterArea from "../components/Layout/FooterArea";

const CancellationsPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <TopHeader />
      <div className="col-md-12 ">
        <img
          src="/images/Cancellations-policy.jpg"
          alt="contactus"
          style={{ width: "100%" }}
        />
      </div>
      <Box padding="30px">
        <Heading>Subscription Cancellations Policy</Heading>
        <p>
          At Kirush Essential Multiservices Pvt. Ltd., we strive to offer
          high-quality products and services that meet your expectations.
          However, we understand that circumstances may arise where you need to
          cancel your order or subscription. This Cancellations Policy explains
          the terms and conditions for canceling your orders, subscriptions, or
          services purchased from us. Please read the policy carefully to ensure
          you are fully aware of your rights and obligations concerning
          cancellations.
        </p>
        <h2>1. Order Cancellation Policy</h2>
        <p>
          We offer customers the flexibility to cancel orders under certain
          conditions. However, the ability to cancel an order depends on the
          stage of processing and shipment.
        </p>
        <h5>1.1 Cancellation Before Shipment</h5>
        <p>
          If you wish to cancel your order before it has been shipped, you can
          do so by contacting our customer support team as soon as possible.
          Please include the following details in your cancellation request:
        </p>
        <p>
          Your full name Order number Date of purchase Reason for cancellation
          Our team will review your request and confirm whether the order can be
          canceled. If the order has not been processed for shipment, we will
          cancel it and provide a full refund to the original payment method
          within [Insert Time Frame] working days.
        </p>
        <h3>1.2 Cancellation After Shipment</h3>

        <p>
          If your order has already been shipped, it is no longer eligible for
          cancellation. In such cases, you may follow our Return and Refund
          Policy, where you can return the product once received, subject to the
          conditions of our returns process.
        </p>

        <p>
          Once an order is shipped, you may refuse delivery, in which case the
          product will be returned to us. Upon receiving the returned product,
          we will process your refund minus shipping and handling charges within
          [Insert Time Frame] working days.
        </p>
        <h4>1.3 Cancellation of Customized or Personalized Orders</h4>
        <p>
          Customized or personalized products are not eligible for cancellation
          once the order has been placed. These products are created
          specifically for you, and we cannot accept returns or cancellations
          unless there is a defect in the product or the item does not meet the
          agreed specifications.
        </p>
        <h2>2. Subscription Cancellation Policy</h2>
        <p>
          In addition to individual orders, Kirush Essential Multiservices Pvt.
          Ltd. may offer subscription-based services. Our cancellation policy
          for subscriptions is as follows:
        </p>
        <h3>2.1 Monthly Subscriptions</h3>
        <p>
          You may cancel a monthly subscription at any time. If you cancel
          before the start of the next billing cycle, you will not be charged
          for the upcoming month. However, no refunds will be issued for any
          portion of the subscription that has already been billed and paid for.
        </p>
        <p>
          To cancel a monthly subscription, log into your account on our website
          and navigate to the subscription management section. Alternatively,
          you can contact our customer support team to assist with the
          cancellation process.
        </p>
        <h3>2.2 Annual Subscriptions</h3>
        <p>
          Annual subscriptions provide access to services for a full 12-month
          period, billed upfront. You may cancel your annual subscription at any
          time; however, no partial refunds will be provided for the unused
          months of your subscription, except in the case of a violation of
          service terms by Kirush Essential Multiservices Pvt. Ltd.
        </p>
        <h3>2.3 Automatic Renewals</h3>
        <p>
          Subscriptions automatically renew at the end of each billing period,
          whether monthly or annual, unless you cancel the renewal before the
          start of the next cycle. You will receive a reminder before the
          renewal date, and it is your responsibility to cancel the subscription
          if you do not wish to continue.
        </p>
        <h2>3. Cancellation Fees and Charges</h2>
        <p>
          Depending on the stage of your order and the nature of the product or
          service, cancellation fees may apply. Below are the key cancellation
          charges that you should be aware of:
        </p>
        <p>
          Cancellation Before Shipment: No cancellation fees apply for standard
          products that are canceled before shipment. A full refund will be
          issued.
        </p>
        <p>
          Cancellation After Shipment: Orders canceled after shipment may incur
          shipping, handling, and return shipping charges, which will be
          deducted from your refund.
        </p>
        <p>
          Customized Orders: Cancellation is not allowed once the creation of a
          customized or personalized order has begun. No refunds will be
          provided unless there is a defect or error on our part.
        </p>
        <p>
          Subscription Cancellation: No cancellation fees apply for subscription
          cancellations, but partial refunds for unused months or days will not
          be provided.
        </p>
        <h2>4. Refund Process</h2>
        <p>
          Once your cancellation request is approved, we will initiate the
          refund process. Refunds will be processed as follows:
        </p>
        <p>
          Refunds for credit card or debit card payments will be processed back
          to the original card used for the purchase within 2to3 working days.
        </p>
        <p>
          Refunds for bank transfers will be issued to the same bank account
          within 2to3 working days.
        </p>
        <p>
          Refunds for any other payment methods, such as digital wallets or
          third-party services, will be processed according to the policies of
          those respective providers.
        </p>
        <p>
          Please note that refund processing times may vary depending on your
          payment provider.
        </p>
        <h2>5. Non-Cancellable Products and Services</h2>
        <p>
          Certain products and services offered by Kirush Essential
          Multiservices Pvt. Ltd. are non-cancellable once purchased. These
          include:
        </p>
        <p>
          Gift Cards: Gift cards purchased from our platform cannot be canceled
          or refunded. Digital Products: Any digital products, including eBooks,
          downloadable content, or other digital services, are non-cancellable
          and non-refundable once delivered or accessed.
        </p>
        <p>
          Event or Workshop Registrations: Once you register for an event or
          workshop, your registration is non-refundable unless the event is
          canceled or rescheduled by Kirush Essential Multiservices Pvt. Ltd.
        </p>
        <h2>6. How to Request a Cancellation</h2>
        <p>To request a cancellation, you may:</p>
        <p>
          Email us: Send a detailed cancellation request to
          support@kirushessential.com. Include your order number, product or
          service details, and reason for cancellation.
        </p>
        <p>
          Call us: Reach our customer service team at +91-XXXXXXXXXX, and our
          representative will assist you with the cancellation process.
        </p>
        <p>
          Submit a form: Fill out the cancellation request form available on our
          website's "Contact Us" page.
        </p>
        <h2>7. Disputes and Issues</h2>
        <p>
          If you encounter any issues with your cancellation request or refund
          process, we encourage you to contact our customer support team for
          assistance. We are committed to resolving any disputes or concerns in
          a timely and fair manner.
        </p>
        <h2>8. Changes to the Cancellation Policy</h2>
        <p>
          Kirush Essential Multiservices Pvt. Ltd. reserves the right to modify
          or update this Cancellation Policy at any time. Any changes to the
          policy will be posted on this page, and the updated policy will be
          effective immediately upon posting.
        </p>
        <p>
          We recommend reviewing this policy regularly to stay informed about
          any changes that may affect your ability to cancel orders or services.
        </p>
        <h2>9. Contact Us</h2>
        <p>
          If you have any questions or concerns about this Cancellation Policy,
          please do not hesitate to contact us:
        </p>
        <p>
          Kirush Essential Multiservices Pvt. Ltd. <br />
          Email: Sallerkitab@gmail.com
          <br />
          Phone: +91 7669001308 <br />
          Address:Company name : Building No./Flat No.: 2561/194, Third Floor
          Road/Street: Tri Nagar City/Town/Village: 2561/194, Third Floor
          District: North West Delhi State: Delhi PIN Code:110035
          <br />
        </p>
        <p>
          This Cancellations Policy provides detailed guidelines on how to
          cancel orders, services, or subscriptions from Kirush Essential
          Multiservices Pvt. Ltd. while outlining the processes, charges, and
          conditions associated with cancellations.
        </p>
      </Box>
      <FooterArea />
    </>
  );
};

export default CancellationsPolicy;
