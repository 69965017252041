import React, { useState, useEffect } from "react";
import { Link, NavLink, redirect, useNavigate } from "react-router-dom";
import { Prices } from "../components/Prices";
import { useCart } from "../context/cart";
import axios from "axios";
import Layout from "./../components/Layout/Layout";
import "../styles/Homepage.css";
import Rate from "./Rate";
import { useAuth } from "../context/auth";
import ProductCategory from "../components/Layout/ProductCategory";
import ScreenProducts from "../components/Layout/ScreenProducts";
import * as mod from "./../../src/url.js";
import { Hourglass, RotatingTriangles } from "react-loader-spinner";
import Slider from "../components/Layout/slider.js";
import _debounce from "lodash/debounce";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FaLaptopCode, FaRegSmile, FaStar } from "react-icons/fa";
import { BiSolidLike } from "react-icons/bi";
import { FaPeopleGroup } from "react-icons/fa6";
import { LuFileBadge } from "react-icons/lu";
import { CgCommunity } from "react-icons/cg";
import { Container } from "react-bootstrap";
import ProductSliders from "./ProductSliders.js";
import mainbanner from "./../img/RAIN-BANNER.png";

const HomePage = () => {
  const navigate = useNavigate();
  const [cart, setCart] = useCart();
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  useEffect(() => {
    getTotal();
    getAllProducts();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  // Get total count
  const getTotal = async () => {
    try {
      const { data } = await axios.get(
        `${mod.api_url}/api/v1/product/product-count`
      );
      setTotal(data?.total);
    } catch (error) {
      console.error("Error fetching total count:", error);
    }
  };

  // Get products
  const getAllProducts = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${mod.api_url}/api/v1/product/product-list/${page}`
      );
      setLoading(false);

      if (page === 1) {
        setProducts(data.products);
      } else {
        setProducts((prevProducts) => [...prevProducts, ...data?.products]);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching products:", error);
    }
  };

  // Load more on button click
  const handleLoadMoreClick = async () => {
    setLoadingMore(true);

    // Simulate loading with a timeout
    await new Promise((resolve) => setTimeout(resolve, 10));

    setPage((prevPage) => prevPage + 1);
    setLoadingMore(false);
  };

  useEffect(() => {
    const handleScroll = _debounce(() => {
      const { scrollTop, clientHeight, scrollHeight } =
        document.documentElement;

      if (scrollTop + clientHeight >= scrollHeight - 200) {
        if (!loading && !loadingMore && products.length < total) {
          setPage((prevPage) => prevPage + 1);
        }
      }
    }, 20);

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loading, loadingMore, products, total]);

  // Load more when the page changes
  useEffect(() => {
    if (page > 1) {
      getAllProducts();
    }
  }, [page]);

  return (
    <>
      <Layout title={"KITABSALLER E-COMMERCE"}>
        {/* <Namedcategory /> */}
        <Image src={mainbanner} paddingBottom="4px" />
        <Slider />
        <hr
          style={{
            background:
              "linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet)",
            height: "5px",
            border: "none",
          }}
        />
        <ProductCategory />
        <hr
          style={{
            background:
              "linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet)",
            height: "5px",
            border: "none",
          }}
        />
        <ProductSliders />

        <ScreenProducts />
        <hr
          style={{
            background:
              "linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet)",
            height: "5px",
            border: "none",
          }}
        />

        <Box marginTop={2} cursor="pointer" className="all_product">
          <Container maxW="container.xl" p={4}>
            <Grid
              templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(4, 2fr)" }}
              gap={6}
              padding="10px"
            >
              {products?.map((p) => (
                <>
                  <GridItem
                    key={p._id}
                    onClick={() => {
                      navigate(`/product/${p.slug}`);
                      window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: "smooth",
                      });
                    }}
                    borderWidth="1px"
                    borderRadius="lg"
                    overflow="hidden"
                    padding="10px"
                  >
                    <Image
                      src={p.image}
                      alt="uploading...."
                      borderRadius="lg"
                      width="100%"
                      height="auto"
                      objectFit="cover"
                      padding={1}
                    />

                    <VStack p={2} align="stretch">
                      <Heading size="sm" fontFamily="poppins" color="black">
                        {p.name.slice(0, 30)}
                      </Heading>
                      <VStack align="left" display="inline" color="black">
                        <Text fontFamily="poppins">
                          {p.description.slice(0, 40)}...
                        </Text>
                        <Box display="flex">
                          <Text fontSize="small" color="black">
                            Offers: {p.offers}%
                          </Text>
                          <Text
                            fontSize="small"
                            marginLeft="65px"
                            color="black"
                          >
                            Price: ₹ {p.price}
                          </Text>
                        </Box>
                      </VStack>
                      <Flex justifyContent="space-between">
                        <Button
                          width="100%"
                          colorScheme="green"
                          fontSize="small"
                          onClick={() => {
                            const existingProduct = cart.find(
                              (item) => item._id === p._id
                            );
                            if (existingProduct) {
                              navigate("/cart");
                            } else {
                              setCart([...cart, { ...p, quantity: 1 }]);
                              localStorage.setItem(
                                "cart",
                                JSON.stringify([...cart, { ...p, quantity: 1 }])
                              );
                              navigate("/cart");
                            }
                          }}
                        >
                          Buy Now
                        </Button>
                      </Flex>
                    </VStack>
                  </GridItem>
                </>
              ))}
            </Grid>
          </Container>
        </Box>

        <div className="text-center m-4 p-6">
          {products && products.length < total && (
            <Button
              width="30%"
              colorScheme="linkedin"
              fontSize={10}
              className="load-div"
              onClick={(e) => handleLoadMoreClick(e)}
              disabled={loadingMore}
            >
              {loadingMore ? (
                <Hourglass
                  visible={true}
                  height="40"
                  width="40"
                  ariaLabel="hourglass-loading"
                  wrapperStyle={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "0 auto",
                    padding: "5px",
                    height: "50px",
                  }}
                  wrapperClass=""
                  colors={["#306cce", "#72a1ed"]}
                />
              ) : (
                <>
                  <Hourglass
                    visible={true}
                    height="30"
                    width="30"
                    ariaLabel="hourglass-loading"
                    wrapperStyle={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "0 auto",
                      padding: "5px",
                      height: "50px",
                    }}
                    wrapperClass=""
                    colors={["white", "white"]}
                  />
                </>
              )}
            </Button>
          )}
        </div>

        <Box background="#c17130" padding="10px">
          <Text
            fontSize="xx-large"
            color="white"
            textAlign="center"
            marginTop="20px"
            marginBottom="20px"
            fontWeight="600"
          >
            What to expect from a KITAB SALLER
          </Text>
          <div className="row">
            <div className="col-md-6">
              <Text
                display="flex"
                fontSize="x-large"
                fontWeight="500"
                fontFamily="poppins"
                color="white"
              >
                {" "}
                <FaRegSmile style={{ marginRight: "10px" }} />
                Learn at your own pace
              </Text>
              <Text color="white">
                Enjoy learning from home without a set schedule and with an
                easy-to-follow method. You set your own pace.
              </Text>
            </div>
            <div className="col-md-6">
              <Text
                display="flex"
                fontSize="x-large"
                fontWeight="500"
                fontFamily="poppins"
                color="white"
              >
                <FaLaptopCode style={{ marginRight: "10px" }} /> Get front-row
                seats
              </Text>
              <Text color="white">
                Videos of the highest quality, so you don't miss a single
                detail. With unlimited access, you can watch them as many times
                as you need to perfect your technique.
              </Text>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <Text
                display="flex"
                fontSize="x-large"
                fontWeight="500"
                fontFamily="poppins"
                color="white"
              >
                <BiSolidLike style={{ marginRight: "10px" }} /> Learn from the
                best professionals
              </Text>
              <Text color="white">
                Learn valuable methods and techniques explained by top experts
                in the creative sector..
              </Text>
            </div>
            <div className="col-md-6">
              <Text
                display="flex"
                fontSize="x-large"
                fontWeight="500"
                fontFamily="poppins"
                color="white"
              >
                <FaPeopleGroup style={{ marginRight: "10px" }} /> Share
                knowledge and ideas
              </Text>
              <Text color="white">
                Ask questions, request feedback, or offer solutions. Share your
                learning experience with other students in the community who are
                as passionate about creativity as you are.
              </Text>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <Text
                display="flex"
                fontSize="x-large"
                fontWeight="500"
                fontFamily="poppins"
                color="white"
              >
                <LuFileBadge style={{ marginRight: "10px" }} /> Meet expert
                teachers
              </Text>
              <Text color="white">
                Each expert teaches what they do best, with clear guidelines,
                true passion, and professional insight in every lesson.
              </Text>
            </div>
            <div className="col-md-6">
              <Text
                display="flex"
                fontSize="x-large"
                fontWeight="500"
                fontFamily="poppins"
                color="white"
              >
                <CgCommunity style={{ marginRight: "10px" }} /> Connect with a
                global creative community
              </Text>
              <Text color="white">
                The community is home to millions of people from around the
                world who are curious and passionate about exploring and
                expressing their creativity.
              </Text>
            </div>
          </div>
        </Box>
      </Layout>
    </>
  );
};

export default HomePage;
