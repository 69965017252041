import { Box } from "@chakra-ui/react";
import React, { useEffect } from "react";
import TopHeader from "../components/Layout/TopHeader";
import FooterArea from "../components/Layout/FooterArea";

const OurMission = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <TopHeader />
      <div className="col-md-12 ">
        <img
          src="/images/about-us.jpg"
          alt="contactus"
          style={{ width: "100%" }}
        />
      </div>
      <Box padding="30px">
        <h1>About Us</h1>
        <p>
          Welcome to Kirush Essential Multiservices Pvt. Ltd., a company
          dedicated to enriching lives through knowledge and education. Since
          our inception, we have been driven by a single goal: to make
          high-quality books and educational materials accessible to all. We
          believe that books are more than just a collection of pages—they are
          the cornerstone of learning, imagination, and personal growth. At
          Kirush Essential Multiservices, we are committed to nurturing minds by
          providing a diverse range of books that cater to readers of all ages,
          interests, and educational backgrounds.
        </p>
        <h3>Our Journey</h3>
        <p>
          Founded by a passionate team of educators, book lovers, and business
          professionals, Kirush Essential Multiservices Pvt. Ltd. was
          established with a vision to bridge the gap between readers and the
          resources they need for self-development. We realized that access to
          quality books was not always easy, especially in areas where
          bookstores were limited or educational resources were scarce.
          Recognizing this need, we set out to create a platform that not only
          provided books but also curated content that could inspire learning,
          creativity, and curiosity.
        </p>
        <p>
          Our journey began with a simple yet powerful idea: to ensure that
          everyone, regardless of their location or circumstances, has the
          opportunity to expand their horizons through reading. Today, we are
          proud to be a trusted provider of educational materials, offering a
          comprehensive range of books that span various subjects, genres, and
          formats.
        </p>
        <h3>What We Offer</h3>
        <p>
          At Kirush Essential Multiservices Pvt. Ltd., we offer a wide array of
          books, carefully selected to meet the diverse needs of our customers.
          From academic textbooks to bestsellers, children's literature to
          self-help guides, we provide resources that empower individuals to
          learn, grow, and succeed.
        </p>

        <p>
          Academic and Educational Books: We understand the importance of
          education in shaping the future. That’s why we offer a robust
          collection of academic books tailored for students, teachers, and
          educational institutions. Our range covers various subjects including
          science, technology, engineering, mathematics (STEM), arts, and
          humanities, ensuring that learners of all levels can access the
          knowledge they need to excel.
        </p>
        <p>
          Children's Books: We believe that the habit of reading should be
          nurtured from an early age. Our children’s book collection includes
          engaging stories, educational content, and beautifully illustrated
          picture books designed to spark imagination and foster a love for
          reading in young minds.
        </p>
        <p>
          Fiction and Non-Fiction: Whether you're looking for an escape into the
          world of fiction or seeking knowledge through non-fiction, we have an
          extensive selection that caters to a broad range of tastes and
          preferences. From gripping novels to insightful biographies and
          thought-provoking essays, our collection has something for every
          reader.
        </p>
        <p>
          Self-Help and Personal Development: We believe in the power of
          self-improvement, and our carefully curated selection of self-help and
          personal development books is designed to motivate and guide
          individuals towards a more fulfilling and successful life.
        </p>
        <p>
          Reference and Professional Books: For professionals and lifelong
          learners, we offer a wide range of reference materials, manuals, and
          industry-specific books that provide valuable insights and practical
          knowledge.
        </p>
        <h3>Our Mission</h3>
        <p>
          At Kirush Essential Multiservices Pvt. Ltd., our mission is to promote
          lifelong learning and personal growth through the power of books. We
          are dedicated to providing high-quality educational resources that
          inspire, inform, and empower individuals. Our goal is not just to sell
          books but to create a community of learners who are curious, informed,
          and ready to make a positive impact on the world.
        </p>
        <p>
          We are committed to making books accessible to everyone, regardless of
          their location or socio-economic background. By offering affordable
          prices and a wide selection of titles, we aim to ensure that education
          and knowledge are within reach for all.
        </p>
        <h3>Our Values</h3>
        <p>
          Customer-Centric Approach: At Kirush Essential Multiservices Pvt.
          Ltd., our customers are at the heart of everything we do. We strive to
          provide an exceptional experience for each customer, from helping them
          find the right book to ensuring a seamless purchase process.
        </p>
        <p>
          Integrity and Trust: We believe in conducting our business with the
          highest levels of integrity. Our customers trust us to provide quality
          books and educational materials, and we are committed to upholding
          that trust by delivering reliable and honest service.
        </p>
        <p>
          Diversity and Inclusion: We celebrate the diversity of thought,
          culture, and knowledge. Our book selection reflects a wide range of
          perspectives and ideas, ensuring that every reader can find something
          that resonates with them.
        </p>
        <p>
          Innovation and Growth: In a rapidly changing world, we are constantly
          seeking ways to innovate and grow. We embrace new ideas, technologies,
          and approaches that allow us to better serve our customers and adapt
          to their evolving needs.
        </p>
        <h3>Our Vision for the Future</h3>
        <p>
          As we look to the future, our vision is to become a leading provider
          of educational resources, not just in India but globally. We aim to
          expand our reach and offer even more books and services that cater to
          the evolving needs of learners, educators, and readers. We are also
          committed to exploring new formats and technologies that enhance the
          reading experience, such as e-books and online learning platforms.
        </p>
        <p>
          At Kirush Essential Multiservices Pvt. Ltd., we believe that the
          future belongs to those who never stop learning. We are excited to be
          a part of that journey, helping individuals unlock their potential
          through the power of books.
        </p>
        <p>
          Thank you for choosing Kirush Essential Multiservices Pvt. Ltd. Let’s
          continue to learn, grow, and explore the world of knowledge together.
        </p>
      </Box>
      <FooterArea />
    </>
  );
};

export default OurMission;
