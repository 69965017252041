import React, { useEffect, useState } from "react";
import "./../../styles/ProductCategory.css";
import axios from "axios";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import * as mod from "./../../url.js";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Center, Text } from "@chakra-ui/react";

function ProductCategory() {
  const [categories, setCategories] = useState([]);
  const [isImageVisible, setIsImageVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const scrollThreshold = 10;
      if (scrollPosition < scrollThreshold) {
        setIsImageVisible(true);
      } else {
        setIsImageVisible(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getAllCategory = async () => {
    try {
      const { data } = await axios.get(
        `${mod.api_url}/api/v1/course/get-course`
      );
      if (data?.success) {
        setCategories(data?.course);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllCategory();
  }, []);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 600 },
      items: 6,
      slidesToSlide: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 5,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 3,
      slidesToSlide: 1,
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <hr />
      <div className="app_compo">
        <div className="py-3 app ">
          <div className="catogryslid">
            <div className="row">
              <div className="col-12">
                <h2
                  className="mb-3"
                  style={{
                    fontFamily: "monospace",
                    fontSize: 18,
                    color: "black",
                  }}
                >
                  Our Books
                </h2>
              </div>
              <Carousel
                responsive={responsive}
                autoPlay={true}
                autoPlaySpeed={3000}
                // showDots={true}
                removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                infinite={true}
              >
                {categories.map((c, index) => (
                  <div
                    className={`banner_image ${
                      isImageVisible ? "visible" : "visible"
                    }`}
                    key={index}
                  >
                    <div
                      className="course-card"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Link
                        className="dropdown-item headerName"
                        style={{ fontFamily: "monospace", textAlign: "center" }}
                        to={`/course/${c.slug}`}
                      >
                        <div className="course-card-img">
                          <img
                            src={`${mod.api_url}/api/v1/course/singlePhoto-course/${c._id}`}
                            className="catrgory-image"
                            alt="loading..."
                            style={{ display: "block", margin: "0 auto" }}
                          />
                          <p>{c.name}</p>
                        </div>
                      </Link>
                    </div>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductCategory;
