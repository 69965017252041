import { Box, Heading } from "@chakra-ui/react";
import React, { useEffect } from "react";
import TopHeader from "../components/Layout/TopHeader";
import FooterArea from "../components/Layout/FooterArea";

const TermsansConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <TopHeader />
      <div className="col-md-12 ">
        <img
          src="/images/TERM-&-CONDITION.jpg"
          alt="contactus"
          style={{ width: "100%" }}
        />
      </div>
      <Box padding="30px">
        <Heading>Terms and Conditions</Heading>
        <p>
          Welcome to Kirush Essential Multiservices Pvt. Ltd. (“Company,” “we,”
          “us,” or “our”). By using our website, services, and purchasing our
          products, you agree to the following terms and conditions. Please read
          these terms carefully, as they outline the rules and regulations
          governing the use of our website and services. If you do not agree
          with any part of these terms, you are advised to discontinue using our
          services.
        </p>
        <h5>1. Acceptance of Terms</h5>
        <p>
          By accessing and using the services of Kirush Essential Multiservices
          Pvt. Ltd., you acknowledge that you have read, understood, and agree
          to comply with these Terms and Conditions, along with our Privacy
          Policy. These terms apply to all visitors, users, and customers of our
          website and services.
        </p>
        <p>
          We reserve the right to amend or update these terms at any time
          without prior notice. Any changes made to these terms will be
          reflected on this page, and it is your responsibility to review them
          periodically.
        </p>

        <h5>2. Eligibility</h5>
        <p>To use our services, you must:</p>
        <p>
          Be at least 18 years of age or have parental or guardian consent to
          use the website. Ensure that all information you provide is accurate,
          up-to-date, and complete. Comply with all applicable laws and
          regulations while using our services. If we suspect that you are
          underage or using false information, we reserve the right to suspend
          or terminate your account.
        </p>

        <h5>3. User Accounts</h5>
        <p>
          To access certain features of our website, such as making purchases or
          managing orders, you may be required to create a user account. You are
          responsible for maintaining the confidentiality of your account
          information, including your password, and for all activities that
          occur under your account.
        </p>
        <p>
          You agree to notify us immediately of any unauthorized use of your
          account or password. Kirush Essential Multiservices Pvt. Ltd. will not
          be liable for any losses or damages caused by unauthorized use of your
          account.
        </p>
        <h5>4. Purchasing Products</h5>
        <p>
          All orders placed through our website are subject to product
          availability and acceptance by Kirush Essential Multiservices Pvt.
          Ltd. We reserve the right to refuse or cancel any order at our sole
          discretion for any reason, including but not limited to inaccuracies
          in pricing, product descriptions, or other unforeseen issues.
        </p>

        <h5>Pricing:</h5>
        <p>
          The prices listed on our website are subject to change without notice.
          We strive to ensure that all pricing is accurate, but errors may
          occur. In the event of a pricing error, we will notify you and allow
          you the option to cancel the order or proceed with the adjusted price.
        </p>
        <h5>Payment:</h5>
        <p>
          Payment for all orders must be made through the payment methods
          available on our website, including credit/debit cards, net banking,
          or any other accepted method. By submitting your payment information,
          you authorize us to charge the applicable fees.
        </p>
        <h5>Order Confirmation:</h5>
        <p>
          Once you place an order, you will receive a confirmation email
          detailing your purchase. This email serves as an acknowledgment of
          your order but does not constitute acceptance of the order. We reserve
          the right to cancel any order due to errors or issues related to stock
          availability.
        </p>
        <h5>5. Shipping and Delivery</h5>
        <p>
          <strong> Shipping:</strong>
          We offer shipping services to the locations listed on our website.
          Delivery times may vary depending on your location and the shipping
          method chosen during checkout. While we strive to deliver your
          products within the estimated time frame, we do not guarantee exact
          delivery dates.
        </p>
        <p>
          <strong>Shipping Charges:</strong>
          Shipping charges will be calculated based on your location and the
          weight/volume of your order. These charges will be displayed during
          checkout, and you are responsible for covering these costs.
        </p>
        <p>
          <strong>Delivery Issues:</strong>
          <p>
            If your order is delayed or lost, please contact us immediately. We
            will investigate the issue and, where appropriate, offer a
            replacement or refund. However, Kirush Essential Multiservices Pvt.
            Ltd. will not be held liable for delays or issues caused by external
            factors such as courier delays or customs processes for
            international orders.
          </p>
        </p>
        <h5>6. Returns and Refunds</h5>
        <p>
          We want you to be fully satisfied with your purchase from Kirush
          Essential Multiservices Pvt. Ltd. If you are not happy with your
          order, you may request a return or refund in accordance with our
          policies:
        </p>
        <p>
          <strong> Returns:</strong> You may return eligible items within 2to3
          days of receiving your order. Items must be unused, in their original
          packaging, and accompanied by proof of purchase. Certain items, such
          as personalized or clearance products, may not be eligible for return.
        </p>
        <p>
          <strong>Refunds:</strong>Once we receive and inspect your returned
          item, we will notify you via email of the approval or rejection of
          your refund. Approved refunds will be processed to the original
          payment method within 1 offical Day . Please note that shipping
          charges are non-refundable.
        </p>
        <p>
          <strong>Damaged or Defective Items:</strong>If you receive a damaged
          or defective product, please notify us within 2to3 days of receiving
          your order, and we will arrange for a replacement or refund.
        </p>
        <h5>7. Intellectual Property</h5>
        <p>
          All content on our website, including but not limited to text, images,
          logos, product descriptions, and graphics, is the intellectual
          property of Kirush Essential Multiservices Pvt. Ltd. and is protected
          by applicable copyright and trademark laws. You may not copy,
          reproduce, distribute, or otherwise exploit any part of our website
          for commercial purposes without prior written consent from us.
        </p>
        <h5>8. Prohibited Activities</h5>
        <p>While using our website, you agree not to:</p>
        <p>
          Engage in any unlawful activity or violate any applicable laws or
          regulations. Interfere with the operation of our website, including by
          introducing viruses or malicious software.
        </p>
        <p>
          Attempt to gain unauthorized access to any part of our website or user
          accounts. Use any automated means (e.g., bots or scraping tools) to
          access our website.
        </p>
        <p>
          Misrepresent your identity or affiliation with any person or entity.
        </p>
        <p>
          Violation of these terms may result in immediate suspension or
          termination of your account, along with legal action where
          appropriate.
        </p>
        <h5>9. Limitation of Liability</h5>
        <p>
          Kirush Essential Multiservices Pvt. Ltd. is not liable for any direct,
          indirect, incidental, consequential, or special damages arising out of
          your use of our website or services, including but not limited to loss
          of profits, data, or other intangible losses. Your use of our website
          is at your sole risk, and our services are provided on an "as is" and
          "as available" basis.
        </p>
        <h5>10. Indemnification</h5>
        <p>
          You agree to indemnify and hold Kirush Essential Multiservices Pvt.
          Ltd., its officers, employees, and affiliates harmless from any
          claims, liabilities, damages, and expenses (including legal fees)
          arising out of your use of our website or violation of these terms.
        </p>
        <h5>11. Governing Law and Dispute Resolution</h5>
        <p>
          These Terms and Conditions are governed by and construed in accordance
          with the laws of India, without regard to its conflict of law
          principles. Any disputes arising out of or related to these terms will
          be subject to the exclusive jurisdiction of the courts located .
        </p>
        <h5>12. Termination</h5>
        <p>
          We reserve the right to terminate or suspend your access to our
          website and services at any time, without notice or liability, for any
          reason, including but not limited to a breach of these Terms and
          Conditions.
        </p>
        <h5>13. Contact Us</h5>
        <p>
          If you have any questions or concerns regarding these Terms and
          Conditions, please feel free to contact us:
        </p>

        <p>Kirush Essential Multiservices Pvt. Ltd.</p>
        <p>Email: support@kirushessential.com</p>
      </Box>
      <FooterArea />
    </>
  );
};

export default TermsansConditions;
