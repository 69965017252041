import React from "react";
import {
  Box,
  Button,
  Center,
  Heading,
  Text,
  Image,
  Stack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { IoReload } from "react-icons/io5";
// import paymentFailureImage from "./path-to-your-image/failure.png";
import { useCart } from "../context/cart";

const PaymentFailure = () => {
  const [cart, setCart] = useCart(); // Assuming you manage the cart using a context
  const navigate = useNavigate();

  const handleRetry = () => {
    // Redirect to the payment page or any retry logic
    navigate("/cart/payments");
  };

  return (
    <>
      <Center h="100vh" bg="gray.100" p={4}>
        <Box
          maxW="lg"
          w="full"
          bg="white"
          boxShadow="lg"
          borderRadius="lg"
          p={6}
          textAlign="center"
        >
          <Stack spacing={4}>
            {cart.map((item, index) => (
              <Box key={index} p={4} borderWidth={1} borderRadius="md">
                <Image
                  src={item.image}
                  alt={item.name}
                  borderRadius="md"
                  boxSize="100px"
                  objectFit="cover"
                  mx="auto"
                  mb={4}
                />
                <Text fontWeight="bold">{item.name}</Text>
                <Text>Quantity: {item.quantity}</Text>
              </Box>
            ))}
          </Stack>
          <Heading as="h1" size="lg" mb={4} color="red.600">
            Payment Failed
          </Heading>
          <Text fontSize="md" color="gray.600" mb={6}>
            Oops! Something went wrong with your payment. Please try again or
            contact our support team for assistance.
          </Text>

          <Stack direction={["column", "row"]} spacing={4} justify="center">
            <Button
              colorScheme="red"
              onClick={() => navigate("/contact")}
              variant="outline"
            >
              Contact Support
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleRetry}
              rightIcon={<IoReload />}
            >
              Retry Payment
            </Button>
          </Stack>
        </Box>
      </Center>
    </>
  );
};

export default PaymentFailure;
