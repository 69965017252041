import React, { useState } from "react";
import axios from "axios";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "../../styles/AuthStyles.css";
import { useAuth } from "../../context/auth";
import * as mod from "./../../url";
import { Layout } from "antd";
import TopHeader from "../../components/Layout/TopHeader";
import FooterArea from "../../components/Layout/FooterArea";
import "react-toastify/dist/ReactToastify.css";
import { FaEyeLowVision, FaRegEyeSlash } from "react-icons/fa6";
import { FaEye } from "react-icons/fa";
import "./Login.css";
import logo from "./../../img/logo.png";
import loginbanner from "./../../img/kitaabsellar_login.jpg";
import {
  Box,
  Button,
  Container,
  FormControl,
  InputRightElement,
  FormLabel,
  Image,
  Input,
  InputGroup,
  Text,
  VStack,
  Flex,
  Center,
  Grid,
  GridItem,
} from "@chakra-ui/react";

const Login = () => {
  const [phoneOrEmail, setPhoneOrEmail] = useState("");
  const [password, setPassword] = useState("");
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  function validatephone(phoneOrEmail) {
    var regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return regex.test(phoneOrEmail);
  }

  const handleClick = () => setShow(!show);

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(`${mod.api_url}/api/v1/auth/login`, {
        phoneOrEmail,
        password,
      });
      if (res && res.data.success) {
        toast.success(res.data.message || "Login success full");

        setAuth({
          ...auth,
          user: res.data.user,
          token: res.data.token,
        });
        localStorage.setItem("auth", JSON.stringify(res.data));
        navigate(location.state || "/");
      } else {
        toast.error(res.data.message, "wrong your password or user");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message, "Something went wrong");
    }
  };

  return (
    <>
      <div className="row" style={{ width: "100%" }}>
        <Grid
          templateColumns={{ base: "1fr", md: "1fr 1fr" }}
          gap={4}
          width="100%"
          height="100vh"
        >
          {/* First Column (Image) - Hidden on mobile */}
          <GridItem>
            <Image src={loginbanner} className="image_main" />
          </GridItem>
          {/* Second Column (Form) */}
          <GridItem
            alignItems="center"
            justifyContent="center"
            backgroundSize="cover"
            backgroundPosition="center"
            border="1px solid #d4d4d4"
            padding="10px"
            margin="10px"
          >
            <Container width="70%" padding="10px">
              <Center>
                <Image src={logo} width="200px" />
              </Center>
              <Text
                fontSize="x-large"
                marginTop={3}
                textAlign="center"
                fontWeight="700"
              >
                Login
              </Text>
              <VStack spacing="5px" color="black">
                <FormControl id="email" isRequired>
                  <FormLabel>Enter Email/Phone</FormLabel>
                  <Input
                    placeholder="Email/Phone"
                    type="text"
                    autoFocus
                    value={phoneOrEmail}
                    onChange={(e) => setPhoneOrEmail(e.target.value)}
                    id="outlined-basic"
                    label="mobile/Email"
                    variant="outlined"
                    required
                  />
                </FormControl>
                <FormControl id="password" isRequired>
                  <FormLabel>Enter Password</FormLabel>
                  <InputGroup>
                    <Input
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      id="outlined-basic"
                      label="Password"
                      variant="outlined"
                      required
                      type={show ? "text" : "password"}
                    />
                    <InputRightElement
                      width="20%"
                      height="80%"
                      marginRight="0px"
                      marginTop="5px"
                    >
                      <Button h="96%" onClick={handleClick}>
                        {show ? <FaEyeLowVision /> : <FaEye />}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  <Button
                    width="100%"
                    marginTop={2}
                    colorScheme="green"
                    onClick={handleSubmit}
                  >
                    Login
                  </Button>
                </FormControl>
              </VStack>
              <hr />
              <div className="signup-link">
                <NavLink to="/register">Not a member? Signup now</NavLink>
                <div
                  className="pass-link"
                  type="button"
                  onClick={() => navigate("/forgot-password")}
                >
                  <NavLink to="/forgot-password">Forgot password?</NavLink>
                </div>
              </div>
            </Container>
          </GridItem>
          <ToastContainer />
        </Grid>
      </div>
    </>
  );
};

export default Login;
